import { Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { isJwtLoggedIn } from '../Menu';
import { hexToRgbaColorString, scoreColor } from '../Score';
import { ISentenceScore, ScoreApi } from '../api/ScoreApi';
import background from '../asset/background-signup.png';
import ErrorMessageContext, { handleErrorMessage } from '../context/ErrorMessageContext';


export default function PublicSentenceScore() {

    const history = useHistory();
    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)

    // Use the useParams hook to access the ID from the URL
    const { id } = useParams();

    const [sentenceScore, setSentenceScore] = React.useState<ISentenceScore | null>(null);

    const [isLoggedIn, setLoggedIn] = React.useState(false);

    React.useEffect(() => {
        if (isJwtLoggedIn()) {
            setLoggedIn(true);
        } else {
            setLoggedIn(false);
        }
    }, [])

    React.useEffect(() => {
        ScoreApi.getPublicScore(id)
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error("Unable to CourseApi.getAll() " + response.status)
            })
            .then(score => {
                setSentenceScore(score)
                localStorage.setItem('s', id);
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history);
            })
    }, [id]);

    return (
        <Container
            sx={{
                my: 0,
                display: 'flex', // Set display to flex to use Flexbox
                flexDirection: 'column', // Stack children vertically
                justifyContent: 'center', // Center children vertically in the container
                alignItems: 'center', // Center children horizontally
                backgroundImage: `url(${background})`, // Ensure 'background' contains the path to your image
                backgroundSize: 'cover', // Cover the entire container
                backgroundPosition: 'center', // Center the background image        
                minHeight: 'calc(100vh - 82px)', // Set the container's minimum height to fill the entire viewport, minus menu
                width: '100vw', // Adjusted width to fill the entire viewport horizontally
            }}
        >
            {sentenceScore && <>
                <Typography variant="h2" sx={{ textAlign: 'center', color: (theme) => theme.palette.primary.main }}>Congratulate {sentenceScore.first_name} on their hard work!</Typography>

                <TableContainer sx={{ overflowX: 'auto', p: 2, my: 4 }}>
                    <Table aria-label="simple table" sx={{ minWidth: 650, border: 1, borderColor: 'rgba(224, 224, 224, 1)', margin: 'auto', width: 'auto' }}>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={{ py: 1, borderTopLeftRadius: '16px' }}>
                                    <Typography variant="h4">Word</Typography>
                                </TableCell>
                                {sentenceScore.score.map((value, index) =>
                                    <TableCell align="center" sx={{ py: 1 }}>
                                        {sentenceScore.text[index]}
                                    </TableCell>
                                )}
                            </TableRow>

                            <TableRow>
                                <TableCell component="th" scope="row" sx={{ py: 1 }}>
                                    <Typography variant="h4">Correct Pronunciation</Typography>
                                </TableCell>
                                {sentenceScore.score.map((value, index) =>
                                    <TableCell align="center" sx={{
                                        py: 1,
                                        backgroundColor: hexToRgbaColorString(scoreColor(100), 0.05),
                                        color: scoreColor(100)
                                    }}>
                                        {sentenceScore.text_pinyin[index]}
                                    </TableCell>
                                )}
                            </TableRow>

                            <TableRow>
                                <TableCell component="th" scope="row" sx={{
                                    py: 1,
                                    display: 'flex', // Use Flexbox for alignment
                                    alignItems: 'center', // Vertically center the content
                                    gap: '8px', // Optional: Add space between the text and the image
                                }}>
                                    <Typography variant="h4">Your Pronunciation</Typography>
                                    {/* The audio element */}
                                    {/* {selectedRecording && <>
                    <audio ref={selectedAudioRef} src={selectedRecording.audio} />
                    <IconButton onClick={playSelectedAudio} sx={{ p: 0 }}>
                      <img src={PlayBlackIcon} alt="Play" style={{ maxWidth: '24px', maxHeight: '24px' }} />
                    </IconButton>
                  </>} */}
                                </TableCell>
                                {sentenceScore.score.map((value, index) =>
                                    <TableCell align="center" sx={{
                                        py: 1,
                                        backgroundColor: hexToRgbaColorString(scoreColor(sentenceScore ? sentenceScore.likely_score[index] : 100), 0.05),
                                        color: scoreColor(sentenceScore ? sentenceScore.likely_score[index] : 100)
                                    }}>
                                        {sentenceScore && sentenceScore.likely_pinyin[index]}
                                    </TableCell>
                                )}
                            </TableRow>

                            <TableRow>
                                <TableCell component="th" scope="row" sx={{ py: 1, borderBottomLeftRadius: '16px' }}>
                                    <Typography variant="h4">Accuracy</Typography>
                                </TableCell>
                                {sentenceScore.score.map((value, index) =>
                                    <TableCell align="center" sx={{
                                        py: 1,
                                        backgroundColor: hexToRgbaColorString(scoreColor(sentenceScore ? sentenceScore.likely_score[index] : 100), 0.05),
                                        color: scoreColor(sentenceScore ? sentenceScore.likely_score[index] : 100)
                                    }}>
                                        {sentenceScore && Math.round(sentenceScore.score[index])}
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>


            </>
            }
            <Typography sx={{ textAlign: 'center' }}>
                {isLoggedIn ? <>
                    You can attempt this sentence by going to PRONUNCIATION above.
                </> : <>
                    To attempt this sentence, please login or register above.
                </>}
            </Typography>
        </Container >
    );
}
