import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { DialogPrompt, DialogPromptApi, DialogPromptConversationListenSpeak, DialogPromptConversationReadSpeak, DialogPromptConversationReadWithBlankSpeak, DialogPromptConversationRelated, DialogPromptConversationSpeakOnly, IDialogPromptSentence } from "../../api/DialogPromptApi";
import { DialogPromptConversationApi, IDialogPromptConversation } from "../../api/DialogPromptConversationApi";
import ErrorMessageContext, { handleErrorMessage } from "../../context/ErrorMessageContext";

interface DialogPromptConversationRelatedFormProps {
    oldDialogPromptId: number;
    setNewDialogPrompt: React.Dispatch<React.SetStateAction<DialogPrompt | null>>;
    setNewDialogPromptConversation: React.Dispatch<React.SetStateAction<IDialogPromptConversation | null>>;
    id: number;
    dialogId: number;
    orderIndex: number;
    isTeacher: boolean;
    prompt: string;
    isNoteToStudent: boolean;
    yesResponse: string;
    noResponse: string;
    dialogPromptClassName: string;
}

export const DialogPromptConversationRelatedForm: React.FC<DialogPromptConversationRelatedFormProps> = ({
    oldDialogPromptId, setNewDialogPrompt, setNewDialogPromptConversation,
    id, dialogId, orderIndex, isTeacher, prompt, isNoteToStudent, yesResponse, noResponse, dialogPromptClassName }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();

    const [oldDialogPrompt, setOldDialogPrompt] = React.useState<DialogPromptConversationRelated | null>(null)
    const [dialogPromptConversationArray, setDialogPromptConversationArray] = React.useState<IDialogPromptConversation[]>([])
    const [dialogPromptConversationIdSelected, setDialogPromptConversationIdSelected] = React.useState<number>(-1);
    const [conversationText, setConversationText] = React.useState("");
    const [conversationEnabled, setConversationEnabled] = React.useState(true);

    const [dialogPromptRequiredError, setDialogPromptRequiredError] = React.useState(false);
    // const [originalDialogPrompt, setOriginalDialogPrompt] = React.useState<DialogPromptConversationRelated | null>(null);
    const [sentenceBlankList, setSentenceBlankList] = React.useState<string[]>([]);
    const [sentenceBlankErrorList, setSentenceBlankErrorList] = React.useState<string[]>([]);

    React.useEffect(() => {
        DialogPromptConversationApi.getAll()
            .then(response => {
                const list = response.data as IDialogPromptConversation[];
                const sortedListDescending = list.sort((a, b) => b.id - a.id);
                setDialogPromptConversationArray(sortedListDescending)
            })
            .catch(err => {
                setDialogPromptConversationArray([])
                handleErrorMessage(err, setErrorMessage, history);
            })
    }, [])

    React.useEffect(() => {
        DialogPromptApi.getById(oldDialogPromptId)
            .then((response) => {
                const oldDialogPrompt = response.data as DialogPromptConversationRelated;
                setDialogPromptConversationIdSelected(oldDialogPrompt.dialog_prompt_conversation_id ? oldDialogPrompt.dialog_prompt_conversation_id : -1);
                setOldDialogPrompt(oldDialogPrompt)
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history);
            })
    }, [oldDialogPromptId])

    React.useEffect(() => {
        setConversationEnabled(dialogPromptConversationIdSelected <= 0)
        if (dialogPromptConversationIdSelected > 0) {
            const conversation = dialogPromptConversationArray.find(conversation => conversation.id == dialogPromptConversationIdSelected)
            if (conversation) {
                setConversationFields(conversation, oldDialogPrompt)
            }
        } else {
            setConversationText("")
        }
    }, [dialogPromptConversationIdSelected, dialogPromptConversationArray, oldDialogPrompt])

    function setConversationFields(conversation: IDialogPromptConversation, oldDialogPrompt: DialogPromptConversationRelated | null) {
        setConversationText(conversation.dialog_prompt_sentence_list
            ? conversation.dialog_prompt_sentence_list
                .map(item => item.sentence_text)
                .join("\n") // Convert array to newline-separated string
            : ""
        )
        // setOriginalDialogPrompt(dialogPrompt)
        // if (dialogPrompt.dialog_prompt_conversation_id == undefined) {
        //     setDialogPromptConversationIdSelected("")
        // } else {
        //     setDialogPromptConversationIdSelected(String(dialogPrompt.dialog_prompt_conversation_id))
        // }
        // setDialogPromptRequiredError(false)

        // cannot use dialogPromptClassName because it isn't being updated when changing between dialog prompts
        if (oldDialogPrompt && oldDialogPrompt.class_name == "DialogPromptConversationReadWithBlankSpeakModel") {
            var sentenceList = conversation.dialog_prompt_sentence_list;
            if (oldDialogPrompt && oldDialogPrompt.dialog_prompt_conversation_id == conversation.id) {
                sentenceList = (oldDialogPrompt as DialogPromptConversationReadWithBlankSpeak).dialog_prompt_sentence_list
            }
            if (sentenceList) {
                const textList = sentenceList.map(sentence => sentence.sentence_text_alternate == undefined
                    ? (sentence.sentence_text == undefined ? "" : sentence.sentence_text)
                    : sentence.sentence_text_alternate
                );
                setSentenceBlankList(textList);
                setSentenceBlankErrorList(Array.from({ length: textList.length }, () => ''))
            } else {
                setSentenceBlankList([])
                setSentenceBlankErrorList([])
            }
        } else {
            setSentenceBlankList([])
            setSentenceBlankErrorList([])
        }
    }

    // React.useEffect(() => {
    //     if (originalDialogPrompt != null &&
    //         originalDialogPrompt.class_name == "DialogPromptConversationReadWithBlankSpeakModel" &&
    //         Number(dialogPromptConversationIdSelected) == originalDialogPrompt.dialog_prompt_conversation_id) {
    //         const textList = (originalDialogPrompt as DialogPromptConversationReadWithBlankSpeak).dialog_prompt_sentence_list
    //             .map(sentence => sentence.sentence_text_alternate == undefined ? "" : sentence.sentence_text_alternate);
    //         setSentenceBlankList(textList);
    //     } else {
    //         const selectedPrompt = dialogPromptConversationArray.find(prompt => prompt.id == Number(dialogPromptConversationIdSelected));
    //         if (selectedPrompt) {
    //             const textList = selectedPrompt.dialog_prompt_sentence_list.map(sentence => sentence.sentence_text == undefined ? "" : sentence.sentence_text);
    //             setSentenceBlankList(textList);
    //         }
    //     }
    // }, [dialogPromptConversationIdSelected, dialogPromptClassName])

    // const handleDialogPromptConversationListenIdChange = (event: SelectChangeEvent) => {
    //     const selectedValue = Number(event.target.value);
    //     setDialogPromptConversationIdSelected(selectedValue);

    //     // Check if a valid option is selected, you can add more validation if needed
    //     if (selectedValue === "") {
    //         // Option is not selected, handle the error here or use a validation library
    //         setDialogPromptRequiredError(true);
    //     } else {
    //         // Option is selected, clear any error state
    //         setDialogPromptRequiredError(false);
    //     }
    // };


    const handleConversationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newConversationText = e.target.value;
        setConversationText(newConversationText);
        // Convert newline-separated string to array
        const newSentenceBlankList: string[] = newConversationText.split("\n")
        setSentenceBlankList(newSentenceBlankList)
        setSentenceBlankErrorList(Array.from({ length: newSentenceBlankList.length }, () => ''))
    };

    // Function to handle changes in text inputs
    const handleSentenceBlankInputChange = (index: number, event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const updatedValues = [...sentenceBlankList];
        updatedValues[index] = event.target.value;
        setSentenceBlankList(updatedValues);
        setSentenceBlankErrorList(Array.from({ length: updatedValues.length }, () => ''))
    };

    const handleSentenceBlankInputValidation = (index: number) => {
        const selectedDialogPrompt = dialogPromptConversationArray.find(
            (dialogPrompt) => dialogPrompt.id === Number(dialogPromptConversationIdSelected)
        );
        if (selectedDialogPrompt) {
            const originalSentence = selectedDialogPrompt.dialog_prompt_sentence_list[index].sentence_text;
            const blankSentence = sentenceBlankList[index];

            setSentenceBlankErrorList((prevList) => {
                const updatedList = [...prevList]; // Create a copy of the previous list
                updatedList[index] = ""; // Set the i-th element to blank
                return updatedList; // Return the updated list
            });

            if (originalSentence == undefined) {
                setSentenceBlankErrorList((prevList) => {
                    const updatedList = [...prevList]; // Create a copy of the previous list
                    updatedList[index] = 'Missing sentence';
                    return updatedList; // Return the updated list
                });
            }
            else if (originalSentence.length != blankSentence.length) {
                setSentenceBlankErrorList((prevList) => {
                    const updatedList = [...prevList]; // Create a copy of the previous list
                    updatedList[index] = 'Not same length';
                    return updatedList; // Return the updated list
                });
            } else {
                for (let i = 0; i < originalSentence.length; i++) {
                    if (originalSentence[i] !== blankSentence[i] && blankSentence[i] !== "*") {
                        setSentenceBlankErrorList((prevList) => {
                            const updatedList = [...prevList]; // Create a copy of the previous list
                            updatedList[index] = 'Mismatch character / Not star';
                            return updatedList; // Return the updated list
                        });
                    }
                }
            }
        }
    }

    React.useEffect(() => {
        var dialogPrompt = null;
        if (dialogPromptClassName == "DialogPromptConversationListenSpeakModel") {
            dialogPrompt = new DialogPromptConversationListenSpeak(
                id,
                dialogId,
                orderIndex,
                isTeacher,
                prompt,
                isNoteToStudent,
                yesResponse,
                noResponse,
                Number(dialogPromptConversationIdSelected),
            );
        } else if (dialogPromptClassName == "DialogPromptConversationListenWithoutCharacterModel") {
            // do nthing, just skip error
        } else if (dialogPromptClassName == "DialogPromptConversationReadSpeakModel") {
            dialogPrompt = new DialogPromptConversationReadSpeak(
                id,
                dialogId,
                orderIndex,
                isTeacher,
                prompt,
                isNoteToStudent,
                yesResponse,
                noResponse,
                Number(dialogPromptConversationIdSelected),
            );
        } else if (dialogPromptClassName == "DialogPromptConversationReadWithBlankSpeakModel") {
            const dialogPromptSentenceList: IDialogPromptSentence[] = sentenceBlankList.map((sentenceBlank: string, index) => ({
                id: -1,
                dialog_prompt_id: -1,
                sentence_id: -1,
                sentence_text_alternate: sentenceBlank,
                order_index: index
            }));
            dialogPrompt = new DialogPromptConversationReadWithBlankSpeak(
                id,
                dialogId,
                orderIndex,
                isTeacher,
                prompt,
                isNoteToStudent,
                yesResponse,
                noResponse,
                Number(dialogPromptConversationIdSelected),
                dialogPromptSentenceList
            );
        } else if (dialogPromptClassName == "DialogPromptConversationSpeakOnlyModel") {
            dialogPrompt = new DialogPromptConversationSpeakOnly(
                id,
                dialogId,
                orderIndex,
                isTeacher,
                prompt,
                isNoteToStudent,
                yesResponse,
                noResponse,
                Number(dialogPromptConversationIdSelected),
            );
        } else if (dialogPromptClassName == "DialogPromptConversationListenModel") {
            // do nothing. just skip error.
        } else {
            throw new Error("Unknown class name " + dialogPromptClassName);
        }
        setNewDialogPrompt(dialogPrompt);
    }, [dialogPromptClassName, id, dialogId, orderIndex, isTeacher, prompt, isNoteToStudent, yesResponse, noResponse, dialogPromptConversationIdSelected, sentenceBlankList])

    React.useEffect(() => {
        if (dialogPromptConversationIdSelected <= 0) {
            // Convert newline-separated string to array
            const dialogPromptSentenceList: IDialogPromptSentence[] = conversationText.split("\n").map((response: string, index) => ({
                id: 0,
                dialog_prompt_id: id,
                sentence_id: 0,
                sentence_text: response,
                order_index: index
            }));
            const conversation: IDialogPromptConversation = {
                id: 0,
                name: null,
                dialog_prompt_sentence_list: dialogPromptSentenceList
            };
            setNewDialogPromptConversation(conversation)
        } else {
            setNewDialogPromptConversation(null)
        }
        setDialogPromptRequiredError(conversationText.trim().length == 0)
    }, [conversationText])

    // const selectedDialogPrompt = dialogPromptConversationArray.find(
    //     (dialogPrompt) => dialogPrompt.id === Number(dialogPromptConversationIdSelected)
    // );

    return (
        <Stack spacing={4} sx={{ width: '90%', margin: '0 auto', pt: 2, pb: 4 }}>
            <FormControl fullWidth variant="outlined">
                <InputLabel>Select Conversation</InputLabel>
                <Select
                    required
                    label="Select Conversation"
                    value={dialogPromptConversationIdSelected}
                    onChange={(event) => setDialogPromptConversationIdSelected(Number(event.target.value))}
                // onChange={handleDialogPromptConversationListenIdChange}
                >
                    <MenuItem key={-1} value={-1}>New Conversation</MenuItem>
                    {dialogPromptConversationArray
                        .filter(conversation => conversation.dialog_prompt_sentence_list && conversation.dialog_prompt_sentence_list.length > 0) // Filter out null or empty lists
                        .map((conversation, index) => (
                            <MenuItem key={index} value={String(conversation.id)}>
                                {conversation.dialog_prompt_sentence_list
                                    .slice(0, 2) // Take only the first two items
                                    .map(item => item.sentence_text) // Extract the sentence_text
                                    .join(" / ") // Join them with " / "
                                }
                            </MenuItem>
                        ))}
                </Select>
                <FormHelperText style={{ color: dialogPromptRequiredError ? 'red' : 'inherit' }}>
                    Conversation is required!
                </FormHelperText>
            </FormControl>

            <TextField
                label="Conversation"
                value={conversationText}
                onChange={handleConversationChange}
                multiline
                rows={6}
                sx={{ width: '100%' }}
                required
                disabled={!conversationEnabled}
            />
            <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                Alternate lines between speaker A and B
            </Typography>

            {/* {selectedDialogPrompt && (
                <div>
                    <ul>
                        {selectedDialogPrompt.dialog_prompt_sentence_list.map((sentence, index) => (
                            <li key={index}>
                                {index % 2 === 0 ? 'A: ' : 'B: '}
                                {sentence.sentence_text}
                            </li>
                        ))}
                    </ul>
                </div>
            )} */}
            {(dialogPromptClassName == "DialogPromptConversationReadWithBlankSpeakModel") && (<>
                <div>
                    Replace any characters with star *
                </div>
                <div>
                    <ul>
                        {sentenceBlankList.map((sentenceBlank, index) => (
                            <li key={index}>
                                {index % 2 === 0 ? 'A: ' : 'B: '}
                                <TextField
                                    variant="outlined"
                                    value={sentenceBlankList[index] || ''}
                                    onChange={(event) => handleSentenceBlankInputChange(index, event)}
                                    onBlur={() => handleSentenceBlankInputValidation(index)}
                                    error={Boolean(sentenceBlankErrorList[index])} // Set error prop based on error presence
                                    helperText={sentenceBlankErrorList[index]} // Display error message
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            </>)}
        </Stack>
    )
}