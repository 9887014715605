import axios from 'axios';
import { REST_HOSTNAME } from '../Config';

const axiosInstance = axios.create({
    baseURL: REST_HOSTNAME
});

// Add request interceptor
axiosInstance.interceptors.request.use(
    function (config) {
        const jwt = localStorage.getItem('jwt');
        if (jwt) {
            config.headers['x-access-token'] = jwt; // Add JWT to the headers
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export interface ISentence {
    id: number;
    text: string;
    pinyin: string[];
    processing_queue?: number;
}

export type MaxProcessingQueueResponse = {
    [key: number]: number; // Index signature: keys are strings, values are integers
};

export const SentenceApi = {
    getById: function (sentenceId: number) {
        return axiosInstance.request<ISentence>({
            method: "GET",
            url: `/sentence/${sentenceId}`
        });
    },
    createOrUpdate: function (word: string) {
        return axiosInstance.request<ISentence>({
            method: "PUT",
            url: `/sentence/${word}`
        });
    },
    getMaxProcessingQueue: function () {
        return axiosInstance.request<{ [key: number]: number }>({
            method: "GET",
            url: `/max_processing_queue`,
        })
            .then((response) => {
                if (response.status < 300) {
                    const data = response.data;
                    const value = data[0]; // Access the value for the key '0'
                    return value; // Return the extracted value
                }
                throw new Error("Error fetching max processing queue: " + response.status);
            });
    },
    getMaxProcessingQueueForDialogPrompt: function (dialogId: number) {
        return axiosInstance.request<MaxProcessingQueueResponse>({
            method: "GET",
            url: `/max_processing_queue/dialog/${dialogId}`
        })
    },
    getMaxProcessingQueueForDialog: function (lessonId: number) {
        return axiosInstance.request<MaxProcessingQueueResponse>({
            method: "GET",
            url: `/max_processing_queue/lesson/${lessonId}`
        })
    },
    getMaxProcessingQueueForLesson: function (courseId: number) {
        return axiosInstance.request<MaxProcessingQueueResponse>({
            method: "GET",
            url: `/max_processing_queue/course/${courseId}`
        })
    }
}
