import { Stack } from '@mui/material';
import React from 'react';
import { DialogPromptEnterName } from '../../api/DialogPromptApi';
import { IUser, UserApi } from '../../api/UserApi';
import ErrorMessageContext, { handleErrorMessage } from '../../context/ErrorMessageContext';
import ChatBubble from '../ChatBubble';
import SendButtonTextField from '../SendButtonTextField';
import { getAudioByText } from './DialogPromptComponent';

interface DialogPromptEnterNameComponentProps {
    dialogPrompt: DialogPromptEnterName;
    setDialogPromptComplete: (dialogPromptId: number) => void;
    setPromptInputComponent: (component: React.ReactNode | null) => void;
    showAvatar?: boolean;
    chatBubbleContainerRef: React.RefObject<HTMLDivElement>;
    isSettings?: boolean;
}

const DialogPromptEnterNameComponent: React.FC<DialogPromptEnterNameComponentProps> = ({ dialogPrompt, setDialogPromptComplete, setPromptInputComponent, showAvatar = true, chatBubbleContainerRef, isSettings=false }) => {
    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)

    const [studentName, setStudentName] = React.useState<string | null>('');
    const [chatBubbleDone, setChatBubbleDone] = React.useState(false);

    const [promptAudio, setPromptAudio] = React.useState<Blob | null | undefined>(undefined);
    React.useEffect(() => {
        // clear promptInputComponent initially
        setPromptInputComponent(null);
        getAudioByText(history, setErrorMessage, dialogPrompt.prompt, setPromptAudio, isSettings)
    }, []);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const studentName = event.target.value;
        setStudentName(studentName);
    };

    const handleNameChange = () => {
        if (studentName) {
            localStorage.setItem('studentName', studentName);
            const updatedUser: IUser = {
                first_name: studentName,
            };
            // Call the update function
            UserApi.update(updatedUser)
                .then(response => {
                    // Handle the response
                    // console.log('User updated:', response.data);
                })
                .catch(err => {
                    handleErrorMessage(err, setErrorMessage, history);
                });
        }
    };

    const handleButtonClick = () => {
        if (studentName) {
            // save name
            handleNameChange();
            // Perform any desired action with the student name
            setDialogPromptComplete(dialogPrompt.id);
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleButtonClick();
        }
    };

    React.useEffect(() => {
        if (chatBubbleDone) {
            setPromptInputComponent(
                <SendButtonTextField
                    value={studentName || ''}
                    onChange={handleInputChange}
                    onSubmit={handleButtonClick}
                    onKeyDown={handleKeyDown}
                    sx={{ width: '80%', flexGrow: 1 }} // Directly control the width here
                    autoFocus
                />
                // <Stack direction="row" alignItems="center" sx={{ justifyContent: 'flex-end' }} spacing={2}>
                //     <Input
                //         placeholder="Enter your name"
                //         value={studentName || ''}
                //         onChange={handleInputChange}
                //         onKeyDown={handleKeyDown}
                //         autoFocus
                //     />
                //     <Button variant="contained" onClick={handleButtonClick}>
                //         Next
                //     </Button>
                // </Stack>
            )
        }
    }, [studentName, chatBubbleDone]);

    return (
        <Stack>
            <ChatBubble
                isTeacher={dialogPrompt.is_teacher}
                message={dialogPrompt.prompt}
                messageAudio={promptAudio ? promptAudio : null}
                showAvatar={showAvatar}
                setChatBubbleDone={setChatBubbleDone}
                chatBubbleContainerRef={chatBubbleContainerRef}
            />
        </Stack>
    );
};

export default DialogPromptEnterNameComponent;

