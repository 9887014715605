import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Input, InputLabel, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { DialogPrompt, DialogPromptApi, DialogPromptQuizQuestion } from "../../api/DialogPromptApi";
import { IVocabularyWord } from "../../api/VocabularyWordApi";
import ErrorMessageContext, { handleErrorMessage } from "../../context/ErrorMessageContext";
import { arrayBufferToBase64 } from "./DialogPromptMatchWordPictureForm";

interface DialogPromptQuizQuestionFormProps {
    oldDialogPromptId: number;
    setNewDialogPrompt: React.Dispatch<React.SetStateAction<DialogPrompt | null>>;
    id: number;
    dialogId: number;
    orderIndex: number;
    isTeacher: boolean;
    prompt: string;
    isNoteToStudent: boolean;
    yesResponse: string;
    noResponse: string;
    imageSource: string;
    imageSourceUrl: string;
}

export const DialogPromptQuizQuestionForm: React.FC<DialogPromptQuizQuestionFormProps> = ({
    oldDialogPromptId, setNewDialogPrompt,
    id, dialogId, orderIndex, isTeacher, prompt, isNoteToStudent, yesResponse, noResponse, imageSource, imageSourceUrl }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();

    const [teacherChinesePrompt, setTeacherChinesePrompt] = React.useState<string>('');
    const [correctVocabularyWordId, setCorrectVocabularyWordId] = React.useState<number>(0);
    const [correctVocabularyWord, setCorrectVocabularyWord] = React.useState<string>('');
    const [correctVocabularyWordImage, setCorrectVocabularyWordImage] = React.useState<string|null>('');
    const [correctVocabularyWordImageUrl, setCorrectVocabularyWordImageUrl] = React.useState<string|null>('');
    const [incorrectVocabularyWordList, setIncorrectVocabularyWordList] = React.useState<IVocabularyWord[]>([]);
    const [displayPinyin, setDisplayPinyin] = React.useState<boolean|null>(false)


    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);
    const [wordIndexToDelete, setWordIndexToDelete] = React.useState<number | null>(null);

    const handleTeacherChinesePromptChange = (e: React.ChangeEvent<HTMLInputElement>) => { setTeacherChinesePrompt(e.target.value); };
    const handleCorrectVocabularyWordChange = (e: React.ChangeEvent<HTMLInputElement>) => { setCorrectVocabularyWord(e.target.value); };
    const handleDisplayPinyinChange = (e: React.ChangeEvent<HTMLInputElement>) => { setDisplayPinyin(e.target.checked); };

    React.useEffect(() => {
        // is oldDialogPromptId in the update queue (currently being saved)?
        // if so, display the object in memory
        // const oldDialogPromptTuple = dialogPromptUpdateQueue.find((dialogPromptTuple) => dialogPromptTuple.dialogPrompt.id === oldDialogPromptId)
        // if (oldDialogPromptTuple === undefined || oldDialogPromptTuple === null) {
            DialogPromptApi.getById(oldDialogPromptId)
                .then((response) => {
                    const oldDialogPrompt = response.data as DialogPromptQuizQuestion;
                    setDialogPromptFields(oldDialogPrompt);
                })
                .catch(err => {
                    handleErrorMessage(err, setErrorMessage, history);
                })
        // } else {
        //     setDialogPromptFields(oldDialogPromptTuple.dialogPrompt as DialogPromptQuizQuestion)
        // }
    }, [oldDialogPromptId])


    function setDialogPromptFields(dialogPrompt: DialogPromptQuizQuestion) {
        if (dialogPrompt.class_name == "DialogPromptQuizQuestionModel") {
            setTeacherChinesePrompt(dialogPrompt.teacher_chinese_prompt);
            setCorrectVocabularyWordId(dialogPrompt.correct_vocabulary_word_id);
            setCorrectVocabularyWord(dialogPrompt.correct_vocabulary_word.word);
            setCorrectVocabularyWordImage(dialogPrompt.correct_vocabulary_word.image);
            setCorrectVocabularyWordImageUrl(dialogPrompt.correct_vocabulary_word.image_url);
            setIncorrectVocabularyWordList(dialogPrompt.incorrect_vocabulary_word_list);
            setDisplayPinyin(dialogPrompt.display_pinyin);
        } else {
            setTeacherChinesePrompt('');
            setCorrectVocabularyWordId(0);
            setCorrectVocabularyWord('');
            setCorrectVocabularyWordImage('');
            setCorrectVocabularyWordImageUrl('');
            setIncorrectVocabularyWordList([]);
            setDisplayPinyin(null)
        }
        setDeleteConfirmationOpen(false);
        setWordIndexToDelete(null);
    }

    React.useEffect(() => {
        const word = {
            id: correctVocabularyWordId,
            word: correctVocabularyWord,
            image: correctVocabularyWordImage,
            image_url: correctVocabularyWordImageUrl,
            audio_sentence: '',
            audio_sentence_id: 0,
            dialog_prompt_id: dialogId,
            teacher_english_prompt: '',
            teacher_chinese_prompt: '',
            teacher_chinese_prompt_sentence_id: 0,
            student_response_prompt: '',
            student_response_prompt_sentence_id: 0,
            word_pinyin: [],
            audio_sentence_pinyin: [],
            teacher_chinese_prompt_pinyin: []
        }
        const dialogPrompt = new DialogPromptQuizQuestion(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            yesResponse,
            noResponse,
            imageSource,
            imageSourceUrl,
            teacherChinesePrompt,
            correctVocabularyWordId,
            word,
            incorrectVocabularyWordList,
            displayPinyin
        );
        setNewDialogPrompt(dialogPrompt);
    }, [id, dialogId, orderIndex, isTeacher, prompt, isNoteToStudent, yesResponse, noResponse, imageSource, imageSourceUrl, 
        teacherChinesePrompt, correctVocabularyWordId, correctVocabularyWord, correctVocabularyWordImage, correctVocabularyWordImageUrl, incorrectVocabularyWordList, displayPinyin])

    const handleCorrectImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {

        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            const reader = new FileReader();

            reader.onloadend = () => {
                // Code to handle the reader result
                let base64String: string;
                let result = reader.result;
                if (typeof result === 'string') {
                    base64String = result;
                } else if (result instanceof ArrayBuffer) {
                    base64String = arrayBufferToBase64(result)
                } else {
                    base64String = ""
                }
                setCorrectVocabularyWordImage(base64String);
            };

            reader.readAsDataURL(file);
        }
    };
    const handleDeleteCorrectImage = () => {
        // Set the wordImage state to an empty string to remove the image
        setCorrectVocabularyWordImage('');
        setCorrectVocabularyWordImageUrl('');
    };

    const handleAddVocabularyWord = () => {
        const newVocabularyWord: IVocabularyWord = {
            id: 0,
            word: '',
            image: '',
            image_url: '',
            audio_sentence: '',
            audio_sentence_id: 0,
            dialog_prompt_id: 0,
            teacher_english_prompt: '',
            teacher_chinese_prompt: '',
            teacher_chinese_prompt_sentence_id: 0,
            student_response_prompt: '<Answer/>',
            student_response_prompt_sentence_id: 0,
            word_pinyin: [],
            audio_sentence_pinyin: [],
            teacher_chinese_prompt_pinyin: []
        };
        if (incorrectVocabularyWordList) {
            setIncorrectVocabularyWordList([...incorrectVocabularyWordList, newVocabularyWord]);
        } else {
            setIncorrectVocabularyWordList([newVocabularyWord]);
        }
    };

    const handleVocabularyWordChange = (
        index: number,
        field: string,
        value: string
    ) => {
        setIncorrectVocabularyWordList((prevWords) =>
            prevWords.map((word, i) => {
                if (i === index) {
                    return { ...word, [field]: value };
                }
                return word;
            })
        );
    };
    const handleIncorrectVocabularyWordImageUpload = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            const reader = new FileReader();

            reader.onloadend = () => {
                // Code to handle the reader result
                let base64String: string;
                let result = reader.result;
                if (typeof result === 'string') {
                    base64String = result;
                } else if (result instanceof ArrayBuffer) {
                    base64String = arrayBufferToBase64(result)
                } else {
                    base64String = ""
                }
                setIncorrectVocabularyWordList((prevWords) =>
                    prevWords.map((word: IVocabularyWord, i) => {
                        if (i == index) {
                            return { ...word, image: base64String }
                        }
                        return word;
                    })
                )
            };

            reader.readAsDataURL(file);
        }
    };
    const handleDeleteVocabularyWord = (index: number) => {
        setWordIndexToDelete(index);
        setDeleteConfirmationOpen(true);
    };

    const handleConfirmDelete = () => {
        if (wordIndexToDelete !== null) {
            setIncorrectVocabularyWordList(prevList => prevList.filter((word, index) => index !== wordIndexToDelete));
        }
        setWordIndexToDelete(null);
        setDeleteConfirmationOpen(false);
    };

    const handleCancelDelete = () => {
        setWordIndexToDelete(null);
        setDeleteConfirmationOpen(false);
    };
    return (<>
        <Stack spacing={4} sx={{ width: '90%', margin: '0 auto', pt: 2, pb: 4 }}>
            <div style={{ width: '100%' }}>
                <TextField
                    label="Chinese Prompt (optional)"
                    value={teacherChinesePrompt}
                    onChange={handleTeacherChinesePromptChange}
                    fullWidth
                />
                <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                    Use &lt;Answer/&gt; to mark where the response is inserted
                </Typography>
            </div>

            <FormControlLabel
                    control={<Checkbox checked={displayPinyin ? displayPinyin : false} onChange={handleDisplayPinyinChange} />}
                    label="Display answers with Pinyin"
                />

            <h3>Correct Answer</h3>
            <TextField
                label="Word"
                value={correctVocabularyWord}
                onChange={handleCorrectVocabularyWordChange}
            />
            <Box>
                <InputLabel htmlFor="image-upload">Prompt with Image</InputLabel>
                <Input
                    type="file"
                    id="image-upload"
                    inputProps={{ accept: "image/*" }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCorrectImageUpload(e)}
                />
            </Box>
            <img
                src={correctVocabularyWordImage ? correctVocabularyWordImage : (correctVocabularyWordImageUrl ? correctVocabularyWordImageUrl : undefined)}
                alt="Uploaded"
                style={{
                    maxHeight: '200px', maxWidth: '200px',
                    height: 'auto', width: 'auto',
                    margin: '0 auto', // Add this line to center the image horizontally
                    display: 'block', // Add this line to ensure the margin works correctly
                }}
            />
            {/* Add the delete image button */}
            {((correctVocabularyWordImage && correctVocabularyWordImage != "broken") || (correctVocabularyWordImageUrl && correctVocabularyWordImageUrl != "broken"))&& (
                <Box display="inline-block">
                    <Button variant="outlined" onClick={handleDeleteCorrectImage}>
                        Delete Image
                    </Button>
                </Box>
            )}
        </Stack>


        {incorrectVocabularyWordList && incorrectVocabularyWordList.map((word, index) => {
            // Assuming word.image contains the ArrayBuffer
            return (

                <Stack spacing={4} sx={{ width: '90%', margin: '0 auto', pt: 2, pb: 4 }} key={index}>
                    <h3>Incorrect Answer</h3>
                    <TextField
                        label="Word"
                        value={word.word}
                        onChange={(e) =>
                            handleVocabularyWordChange(index, 'word', e.target.value)
                        }
                    />
                    <Box>
                        <InputLabel htmlFor="image-upload">Prompt with Image</InputLabel>
                        <Input
                            type="file"
                            id="image-upload"
                            inputProps={{ accept: "image/*" }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleIncorrectVocabularyWordImageUpload(index, e)}
                        />
                    </Box>
                    <img
                        src={word.image ? word.image : (word.image_url ? word.image_url : undefined)}
                        alt="Uploaded"
                        style={{
                            maxHeight: '200px', maxWidth: '200px',
                            height: 'auto', width: 'auto',
                            margin: '0 auto', // Add this line to center the image horizontally
                            display: 'block', // Add this line to ensure the margin works correctly
                        }}
                    />
                    {/* {word.image && word.image != "broken" && (
                        TODO add delete image button? needed in DialgoPlanLlmPromptQuizQuestionForm also
                    )} */}
                    <Button variant="outlined" onClick={() => handleDeleteVocabularyWord(index)}>
                        Delete
                    </Button>
                </Stack>
            )
        })}
        <Button variant="outlined" onClick={handleAddVocabularyWord}>
            Add Incorrect Answer
        </Button>
        <Dialog open={deleteConfirmationOpen} onClose={handleCancelDelete}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
                Are you sure you want to delete this incorrect answer {wordIndexToDelete !== null && (<>{incorrectVocabularyWordList[wordIndexToDelete].word}</>)} ?
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelDelete} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleConfirmDelete} color="primary">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    </>);
};
