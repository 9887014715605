import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { CorrectedResponse, DialogPromptApi, DialogPromptTypeSpeak } from '../../api/DialogPromptApi';
import { useKaiTiFontStyles } from '../../font/KaiTiFont';
import ChatBubble from '../ChatBubble';

import AddSelfStudyBlueIcon from '../../asset/icon-add-self-study-blue.png';
import MicrophoneBlueIcon from '../../asset/icon-microphone-blue.png';
import RightArrowBlueIcon from '../../asset/icon-right-arrow-blue.png';
import SpeakerBlueIcon from '../../asset/icon-speaker-blue.svg';
import AudioWaveIcon from '../../asset/icons8-audio-wave.gif';

import { useHistory } from 'react-router-dom';
import { sentenceScoreAllGood, sentenceScoreAnyFailed } from '../../Score';
import { AudioApi } from '../../api/AudioApi';
import { CustomSentenceApi } from '../../api/CustomSentenceApi';
import { ISentenceScore, ScoreApi } from '../../api/ScoreApi';
import { ISentence, SentenceApi } from '../../api/SentenceApi';
import ErrorMessageContext, { handleErrorMessage } from '../../context/ErrorMessageContext';
import SendButtonTextField from '../SendButtonTextField';
import { SentenceScoreMediumResult, SentenceScoreTryAgain, SentenceScoreTryAgainNo, SentenceScoreTryAgainYes, getAudioByText } from './DialogPromptComponent';

interface DialogPromptTypeSpeakComponentProps {
    dialogPrompt: DialogPromptTypeSpeak;
    setDialogPromptComplete: (dialogPromptId: number) => void;
    setPromptInputComponent: (component: React.ReactNode | null) => void;
    showAvatar?: boolean;
    chatBubbleContainerRef: React.RefObject<HTMLDivElement>;
    isSettings?: boolean;
}


const DialogPromptTypeSpeakComponent: React.FC<DialogPromptTypeSpeakComponentProps> = ({ dialogPrompt, setDialogPromptComplete, setPromptInputComponent, showAvatar = true, chatBubbleContainerRef, isSettings=false }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();
    const [teacherChinesePromptSentence, setTeacherChinesePromptSentence] = React.useState<ISentence | null>(null);

    const [promptAudio, setPromptAudio] = React.useState<Blob | null | undefined>(undefined);
    const [yesResponseAudio, setYesResponseAudio] = React.useState<Blob | null | undefined>(undefined);
    const [teacherChinesePromptAudio, setTeacherChinesePromptAudio] = React.useState<Blob | null | undefined>(undefined);
    const [askStudentToSpeakPromptAudio, setAskStudentToSpeakPromptAudio] = React.useState<Blob | null | undefined>(undefined);
    const [closestSentencePromptAudio, setClosestSentencePromptAudio] = React.useState<Blob | null | undefined>(undefined);
    const [spokenYesResponseAudio, setSpokenYesResponseAudio] = React.useState<Blob | null | undefined>(undefined);
    const [spokenNoResponseAudio, setSpokenNoResponseAudio] = React.useState<Blob | null | undefined>(undefined);
    const [sentenceScoreMediumResultAudio, setSentenceScoreMediumResultAudio] = React.useState<Blob | null | undefined>(undefined);
    const [sentenceScoreTryAgainAudio, setSentenceScoreTryAgainAudio] = React.useState<Blob | null | undefined>(undefined);
    const [sentenceScoreTryAgainYesAudio, setSentenceScoreTryAgainYesAudio] = React.useState<Blob | null | undefined>(undefined);
    const [sentenceScoreTryAgainNoAudio, setSentenceScoreTryAgainNoAudio] = React.useState<Blob | null | undefined>(undefined);

    React.useEffect(() => {
        getAudioByText(history, setErrorMessage, dialogPrompt.prompt, setPromptAudio)
        getAudioByText(history, setErrorMessage, dialogPrompt.yes_response, setYesResponseAudio)
        getAudioByText(history, setErrorMessage, dialogPrompt.teacher_chinese_prompt, setTeacherChinesePromptAudio)
        getAudioByText(history, setErrorMessage, dialogPrompt.teacher_ask_student_to_speak_prompt, setAskStudentToSpeakPromptAudio)
        getAudioByText(history, setErrorMessage, dialogPrompt.spoken_yes_response, setSpokenYesResponseAudio)
        getAudioByText(history, setErrorMessage, dialogPrompt.spoken_no_response, setSpokenNoResponseAudio)
        getAudioByText(history, setErrorMessage, SentenceScoreMediumResult, setSentenceScoreMediumResultAudio)
        getAudioByText(history, setErrorMessage, SentenceScoreTryAgain, setSentenceScoreTryAgainAudio)
        getAudioByText(history, setErrorMessage, SentenceScoreTryAgainYes, setSentenceScoreTryAgainYesAudio)
        getAudioByText(history, setErrorMessage, SentenceScoreTryAgainNo, setSentenceScoreTryAgainNoAudio)

        if (dialogPrompt.teacher_chinese_prompt_sentence_id) {
            SentenceApi.getById(dialogPrompt.teacher_chinese_prompt_sentence_id)
                .then((response) => {
                    setTeacherChinesePromptSentence(response.data)
                })
                .catch(err => {
                    handleErrorMessage(err, setErrorMessage, history);
                })
        }
    }, [])

    const [teacherEnglishChatBubbleDone, setTeacherEnglishChatBubbleDone] = React.useState(false);
    const [teacherImageDone, setTeacherImageDone] = React.useState(false);

    const imageRef = React.useRef<HTMLImageElement>(null);

    React.useEffect(() => {
        if (teacherEnglishChatBubbleDone) {
            // console.log("teacherEnglishChatBubbleDone=true");
            if ((dialogPrompt.image_src && dialogPrompt.image_src != "broken") || (dialogPrompt.image_src_url && dialogPrompt.image_src_url != "broken")) {
                const handleImageLoad = () => {
                    setTeacherImageDone(true);
                };

                if (imageRef.current) {
                    if (imageRef.current.complete && imageRef.current.naturalHeight !== 0) {
                        // If image is already loaded
                        handleImageLoad();
                    } else {
                        // Image is not yet loaded, attach event listener
                        imageRef.current.addEventListener('load', handleImageLoad);
                    }
                }

                return () => {
                    // console.log("dialogPrompt.image_src return")
                    if (imageRef.current) {
                        // console.log("dialogPrompt.image_src return removeEventListener")
                        imageRef.current.removeEventListener('load', handleImageLoad);
                    }
                };
            } else {
                setTeacherImageDone(true);
            }
        }
    }, [teacherEnglishChatBubbleDone])

    const [teacherChineseChatBubbleDone, setTeacherChineseChatBubbleDone] = React.useState(false);

    React.useEffect(() => {
        if (teacherImageDone) {
            // console.log("teacherImageDone=true");
            if (teacherChinesePromptSentence && teacherChinesePromptSentence.text) {
                setPromptInputComponent(
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <IconButton onClick={handleTeacherChinesePromptSpeakerClick}>
                            <img src={SpeakerBlueIcon} style={{ width: '36px', height: '36px' }} />
                        </IconButton>
                    </div>
                )
            } else {
                setTeacherChineseChatBubbleDone(true)
            }
        }
    }, [teacherImageDone])

    const handleTeacherChinesePromptSpeakerClick = () => {
        if (teacherChinesePromptSentence == null) return;

        const teacher = localStorage.getItem('teacher');
        let audioPromise;

        if (teacher == "male") {
            audioPromise = AudioApi.getMale(teacherChinesePromptSentence.id)
        } else if (teacher == 'female') {
            audioPromise = AudioApi.getFemale(teacherChinesePromptSentence.id)
        } else if (teacher == 'female2') {
            audioPromise = AudioApi.getFemale2(teacherChinesePromptSentence.id)
        } else {
            throw Error("unknown selected teacher name in local storage " + teacher);
        }

        audioPromise
            .then(response => {
                // check if the response was successful
                if (response.status >= 300) {
                    throw new Error('Getting male audio was not ok statuscode=' + response.status + " " + response.statusText);
                }
                return response.data;
            })
            .then(blob => {
                // create a new Audio object and set the source to the blob URL
                const audio = new Audio(URL.createObjectURL(blob));
                audio.addEventListener('ended', () => { setTeacherChineseChatBubbleDone(true) }); // Listen to the 'ended' event
                audio.play();
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history);
            })
    };

    function isStudentResponsePromptEmpty(prompt: string | null) {
        return prompt == null || prompt == undefined || prompt.trim().toLowerCase() == "<answer/>"
    }

    const [studentAnswer, setStudentAnswer] = React.useState<string | null>('');

    React.useEffect(() => {
        if (teacherChineseChatBubbleDone) {
            // console.log("teacherChineseChatBubbleDone=true");
            setPromptInputComponent(
                <Stack direction="row" alignItems="center" justifyContent="center">
                    {/* Left-aligned X icon */}
                    <IconButton onClick={handleCancelClick}>
                        <img src={RightArrowBlueIcon} style={{ width: '36px', height: '36px' }} />
                    </IconButton>
                    <SendButtonTextField
                        value={studentAnswer || ''}
                        onChange={handleInputChange}
                        onSubmit={handleButtonClick}
                        onKeyDown={handleKeyDown}
                        required
                        sx={{ width: '80%', flexGrow: 1 }} // Directly control the width here
                        autoFocus
                    />

                    {/* <Stack direction="row" alignItems="center" sx={{ justifyContent: 'flex-end', flexGrow: 1 }} spacing={2}>
                        <Input
                            placeholder="Please type your answer"
                            value={studentAnswer || ''}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                            autoFocus
                        />
                        <Button variant="contained" onClick={handleButtonClick}>
                            Next
                        </Button>
                    </Stack> */}
                </Stack>
            )
            if (isStudentResponsePromptEmpty(dialogPrompt.student_response_prompt)) {
                // skip student chat bubble if there is none
                setStudentChatBubbleDone(true);
            }
        }
    }, [studentAnswer, teacherChineseChatBubbleDone])

    const [studentChatBubbleDone, setStudentChatBubbleDone] = React.useState(false);

    React.useEffect(() => {
        if (studentChatBubbleDone) {
            // console.log("studentChatBubbleDone=true");
            // need to scroll after this effect for some reason.
            // scrolling didn't work after teacherChineseChatBubbleDone
            if (imageRef.current) {
                // console.log("studentChatBubbleDone imageRef scrollIntoView")
                imageRef.current.scrollIntoView({ behavior: 'smooth' });
            }
            if (chatBubbleContainerRef.current) {
                chatBubbleContainerRef.current.scrollIntoView({ behavior: 'smooth' });
                // console.log("studentChatBubbleDone, chatBubbleContainerRef, scrollIntoView")
            }
        }
    }, [studentChatBubbleDone])

    const [answerSubmitted, setAnswerSubmitted] = React.useState<boolean>(false);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        setStudentAnswer(inputValue);
        // console.log("handleInputChagnge " + inputValue);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && studentAnswer && studentAnswer.trim().length > 0) {
            setAnswerSubmitted(true);
        }
    };

    const handleButtonClick = () => {
        if (studentAnswer && studentAnswer.trim().length > 0) {
            setAnswerSubmitted(true);
        }
    };

    const [studentAnswerComponentList, setStudentAnswerComponentList] = React.useState<any[]>([]);
    const [studentAnswerDone, setStudentAnswerDone] = React.useState(false);
    const [correctedResponse, setCorrectedResponse] = React.useState<CorrectedResponse | null>(null);

    React.useEffect(() => {
        if (teacherChineseChatBubbleDone && answerSubmitted && studentAnswer) {
            if (dialogPrompt.dialog_prompt_sentence_list == null) {
                dialogPrompt.dialog_prompt_sentence_list = [];
            }
            const studentBubble = (
                <ChatBubble
                    isTeacher={false}
                    message={studentAnswer}
                    messageAudio={null} // no audio for students
                    showAvatar={showAvatar}
                    chatBubbleContainerRef={chatBubbleContainerRef}
                    useChineseFont={true}
                />
            );
            setStudentAnswer('');
            setAnswerSubmitted(false);
            setStudentAnswerComponentList((prevComponentList) => [...prevComponentList, studentBubble]);
            DialogPromptApi.getDialogPromptTypeSpeakMatch(dialogPrompt.id, studentAnswer)
                .then((response) => {
                    const correctedResponse = response.data;
                    setCorrectedResponse(correctedResponse)
                    if (correctedResponse.is_correct) {
                        const teacherBubble = (
                            <ChatBubble
                                isTeacher={true}
                                message={dialogPrompt.yes_response}
                                messageAudio={yesResponseAudio ? yesResponseAudio : null}
                                showAvatar={showAvatar}
                                chatBubbleContainerRef={chatBubbleContainerRef}
                                setChatBubbleDone={setStudentAnswerDone}
                            />
                        );
                        setStudentAnswerComponentList((prevComponentList) => [...prevComponentList, teacherBubble]);
                    } else {
                        const teacherBubble = (
                            <ChatBubble
                                isTeacher={true}
                                message={correctedResponse.reason_for_incorrect}
                                messageAudio={null}  // text is dynamic and not saved
                                showAvatar={showAvatar}
                                chatBubbleContainerRef={chatBubbleContainerRef}
                            />
                        );
                        setStudentAnswerComponentList((prevComponentList) => [...prevComponentList, teacherBubble]);
                    }
                })
                .catch(err => {
                    handleErrorMessage(err, setErrorMessage, history);
                })
        }
    }, [answerSubmitted])

    React.useEffect(() => {
        if (correctedResponse) {
            getAudioByText(history, setErrorMessage, correctedResponse.closest_sentence_text, setClosestSentencePromptAudio)
        }
    }, [correctedResponse])

    React.useEffect(() => {
        if (chatBubbleContainerRef.current) {
            chatBubbleContainerRef.current.scrollIntoView({ behavior: 'smooth' });
            // console.log("studentAnswerComponentList, scrollIntoView")
        }
    }, [studentAnswerComponentList])



    const [addSentenceDialogOpen, setAddSentenceDialogOpen] = React.useState(false);
    const [addSentenceSuccessMessage, setAddSentenceSuccessMessage] = React.useState('');

    const handleAddSentenceClick = () => {
        setAddSentenceDialogOpen(true);
    };

    const handleConfirm = () => {
        if (correctedResponse) {
            const sentenceId = correctedResponse.closest_sentence_id
            CustomSentenceApi.createBySentenceId(sentenceId)
                .then(response => {
                    if (response.status < 300) {
                        return response.data;
                    }
                    throw new Error('Unable to create sentence statuscode=' + response.status + " " + response.statusText);
                })
                .then(list => {
                    // Update success message
                    setAddSentenceSuccessMessage('Sentence added successfully!');
                    // Don't Close the dialog, allow user to see success message
                    // setAddSentenceDialogOpen(false);
                })
                .catch(err => {
                    if (err.response.status == 490) {
                        setAddSentenceSuccessMessage("Please validate your email before adding sentences. You can validate your email in your profile.");
                    } else if (err.response.status == 491) {
                        setAddSentenceSuccessMessage(err.response['data']);
                    } else if (err.response.status == 492) {
                        setAddSentenceSuccessMessage("This user is read only.");
                    } else {
                        console.error(err);
                        setAddSentenceSuccessMessage(err.response.data.message);
                    }
                })
        }
    };

    const handleClose = () => {
        setAddSentenceDialogOpen(false);
        setAddSentenceSuccessMessage('')
    };

    const [isRecording, setIsRecording] = React.useState(false);
    const [isScoring, setIsScoring] = React.useState(false);
    const [sentenceScoreList, setSentenceScoreList] = React.useState<ISentenceScore[]>([]);
    const [chatBubbleSentenceScoreDone, setChatBubbleSentenceScoreDone] = React.useState<boolean[]>([]);
    const [chatBubbleTeacherResponseDone, setChatBubbleTeacherResponseDone] = React.useState<boolean[]>([]);
    const [tryAgainClickedList, setTryAgainClickedList] = React.useState<(boolean | null)[]>([]);

    const [teacherSpeakRequestDone, setTeacherSpeakRequestDone] = React.useState(false);
    const [closestSentencePromptDone, setClosestSentencePromptDone] = React.useState(false);

    const [chatBubbleTryAgainDone, setChatBubbleTryAgainDone] = React.useState(false);
    const [chatBubbleContinueDone, setChatBubbleContinueDone] = React.useState(false);

    React.useEffect(() => {
        if (studentAnswerDone && chatBubbleTryAgainDone == false) {
            if (sentenceScoreList.length == 0 || sentenceScoreList[sentenceScoreList.length - 1].passing_grade == false || tryAgainClickedList[sentenceScoreList.length - 1]) {
                setPromptInputComponent(
                    <Stack direction="row" alignItems="center" justifyContent="center">
                        {/* Left-aligned X icon */}
                        <IconButton onClick={handleCancelClick}>
                            <img src={RightArrowBlueIcon} style={{ width: '36px', height: '36px' }} />
                        </IconButton>
                        <Stack direction="row" alignItems="center" sx={{ justifyContent: 'center', flexGrow: 1 }} spacing={2}>
                            {/* <IconButton onClick={handleSpeakerClick}>
                                <VolumeUpRoundedIcon fontSize="large" />
                            </IconButton> */}
                            <IconButton onClick={isRecording ? stopRecording : startRecording}>
                                {isRecording ? <img src={AudioWaveIcon} alt="Recording" style={{ width: '32px', height: '32px' }} />
                                    : isScoring ? <CircularProgress size={24} sx={{ width: '24px', height: '24px' }} />
                                        : <img src={MicrophoneBlueIcon} style={{ width: '36px', height: '36px' }} />}
                            </IconButton>
                        </Stack>
                        <Tooltip title="Add to Self-Study" arrow>
                            <IconButton onClick={handleAddSentenceClick} sx={{ alignSelf: 'center' }}>
                                <img src={AddSelfStudyBlueIcon} style={{ width: '36px', height: '36px' }} />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                )
            }
        }
    }, [sentenceScoreList, isRecording, isScoring, teacherSpeakRequestDone, chatBubbleTryAgainDone]);

    React.useEffect(() => {
        if (chatBubbleContainerRef.current) {
            chatBubbleContainerRef.current.scrollIntoView({ behavior: 'smooth' });
            // console.log("sentenceScoreList, scrollIntoView")
        }
    }, [sentenceScoreList]);



    const mediaRecorderRef = React.useRef<MediaRecorder | null>(null);

    let audioData: Blob | null = null;

    const handleSpeakerClick = (sentenceId: number) => {
        const teacher = localStorage.getItem('teacher');
        let audioPromise;

        // console.log("speaker click studentCorrectResponseSentenceId " + studentCorrectResponseSentenceId);
        // console.log("speaker click sentenceId " + sentenceId);
        if (teacher == "male") {
            audioPromise = AudioApi.getMale(sentenceId)
        } else if (teacher == 'female') {
            audioPromise = AudioApi.getFemale(sentenceId)
        } else if (teacher == 'female2') {
            audioPromise = AudioApi.getFemale2(sentenceId)
        } else {
            throw Error("unknown selected teacher name in local storage " + teacher);
        }


        audioPromise
            .then(response => {
                // check if the response was successful
                if (response.status >= 300) {
                    throw new Error('Getting male audio was not ok statuscode=' + response.status + " " + response.statusText);
                }
                return response.data;
            })
            .then(blob => {
                // create a new Audio object and set the source to the blob URL
                const audio = new Audio(URL.createObjectURL(blob));
                audio.play();
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history);
            })
    };

    const startRecording = () => {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream) => {
                // console.log("startRecording() 1")
                mediaRecorderRef.current = new MediaRecorder(stream);
                mediaRecorderRef.current.addEventListener('dataavailable', handleAudioDataAvailable);
                mediaRecorderRef.current.addEventListener('stop', handleAudioDone);

                mediaRecorderRef.current.start();

                setIsRecording(true);
                // console.log("startRecording() 2")
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history);
            });
    };
    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            // console.log("stopRecording() 1")
            mediaRecorderRef.current.stop();
            mediaRecorderRef.current.removeEventListener('dataavailable', handleAudioDataAvailable);
            mediaRecorderRef.current.removeEventListener('stop', handleAudioDone);
            // console.log("stopRecording() 2")
        }

        setIsRecording(false);
    };

    const handleAudioDataAvailable = (event: BlobEvent) => {
        // console.log("handleAudioDataAvailable() 1")
        if (event.data.size > 0) {
            // console.log("event.data.size > 0")
            audioData = event.data;
        }
    };

    const handleAudioDone = () => {
        // console.log("handleAudioDone() 1")
        if (audioData && correctedResponse) {
            // console.log("handleAudioDone() has audio data 1")
            setIsScoring(true)
            const formData = new FormData();
            formData.append('audio', audioData);

            console.log("handle audio done studentCorrectResponseSentenceId " + correctedResponse.closest_sentence_id)

            ScoreApi.getScore(correctedResponse.closest_sentence_id, formData)
                .then(response => {
                    setIsScoring(false)
                    // check if the response was successful
                    if (response.status >= 300) {
                        throw new Error('Scoring audio was not ok statuscode=' + response.status + " " + response.statusText);
                    }
                    return response.data;
                })
                .then(responseJson => {
                    responseJson.audioData = audioData;
                    setSentenceScoreList((previous) => [...previous, responseJson]);
                })
                .catch(err => {
                    handleErrorMessage(err, setErrorMessage, history);
                })
        }
    }

    React.useEffect(() => {
        if (chatBubbleTryAgainDone) {
            setPromptInputComponent(
                <Stack direction="row" alignItems="center" justifyContent="center">
                    {/* Left-aligned X icon */}
                    <IconButton onClick={handleCancelClick}>
                        <img src={RightArrowBlueIcon} style={{ width: '36px', height: '36px' }} />
                    </IconButton>

                    {/* Center-aligned buttons */}
                    <Stack direction="row" alignItems="center" justifyContent="center" sx={{ flexGrow: 1 }} spacing={2}>
                        <Button variant="contained" onClick={handleNextWordClick} className='secondaryButton'>
                            No, Continue On
                        </Button>
                        <Button variant="contained" onClick={handleTryAgainClick} >
                            Yes, Try Again
                        </Button>
                    </Stack>
                </Stack>

            )
        }
    }, [chatBubbleTryAgainDone]);

    // const handleNextWordClick = () => {
    //     sentenceScoreList.length
    //     setTryAgainClickedList((previous) => [...previous, false]);
    // }
    // const handleTryAgainClick = () => {
    //     setTryAgainClickedList((previous) => [...previous, true]);
    // }

    const handleNextWordClick = () => {
        setChatBubbleTryAgainDone(false)
        updateTryAgainClickedList(false)
    }
    const handleTryAgainClick = () => {
        setChatBubbleTryAgainDone(false)
        updateTryAgainClickedList(true)
    }

    const updateTryAgainClickedList = (tryAgain: boolean) => {
        const index = sentenceScoreList.length - 1;
        setTryAgainClickedList(previous => {
            const updatedArray = [...previous];
            updatedArray[index] = tryAgain;
            return updatedArray;
        })
    }

    const [openCancelDialog, setOpenCancelDialog] = React.useState(false);

    const handleCancelClick = () => {
        setOpenCancelDialog(true);
    };

    const handleCancelDialogConfirm = () => {
        setOpenCancelDialog(false);
        if (teacherChineseChatBubbleDone && answerSubmitted && studentAnswer) {
            // already pass typing, then remainder (speaking part)
            setChatBubbleContinueDone(true);
        } else if (correctedResponse) {
            // didn't pass typing, but did they try at all?
            // then only skip to pronuciation part
            setTeacherChineseChatBubbleDone(true);
            setAnswerSubmitted(true);
        } else {
            // still nothign? skip everything
            setChatBubbleContinueDone(true);
        }
    };

    const handleCancelDialogCancel = () => {
        setOpenCancelDialog(false);
    };

    const kaiTiFontClass = useKaiTiFontStyles();
    const storedLearnWithCharacter = localStorage.getItem('learnWithCharacter');
    const learnWithCharacter = storedLearnWithCharacter ? JSON.parse(storedLearnWithCharacter) : false;

    React.useEffect(() => {
        if (chatBubbleContinueDone) {
            setPromptInputComponent(<div />)
            setDialogPromptComplete(dialogPrompt.id)

        }
    }, [chatBubbleContinueDone])

    return (
        <Stack>
            <ChatBubble
                isTeacher={dialogPrompt.is_teacher}
                message={dialogPrompt.prompt}
                messageAudio={promptAudio ? promptAudio : null}
                showAvatar={showAvatar}
                chatBubbleContainerRef={chatBubbleContainerRef}
                setChatBubbleDone={setTeacherEnglishChatBubbleDone}
            />
            {(teacherEnglishChatBubbleDone && (dialogPrompt.image_src || dialogPrompt.image_src_url)) && (<>
                <img
                    ref={imageRef}
                    src={dialogPrompt.image_src ? dialogPrompt.image_src : (dialogPrompt.image_src_url ? dialogPrompt.image_src_url : undefined)}
                    alt={dialogPrompt.teacher_chinese_prompt ? dialogPrompt.teacher_chinese_prompt : ""}
                    style={{
                        maxHeight: '200px', maxWidth: '200px',
                        height: 'auto', width: 'auto',
                        margin: '0 auto', // Add this line to center the image horizontally
                        display: 'block', // Add this line to ensure the margin works correctly
                    }}
                />
            </>)}
            {(teacherImageDone && teacherChinesePromptSentence && teacherChinesePromptSentence.text) && (<>
                <ChatBubble
                    isTeacher={true}
                    message={teacherChinesePromptSentence && teacherChinesePromptSentence.text ? teacherChinesePromptSentence.text : ''}
                    messageAudio={teacherChinesePromptAudio ? teacherChinesePromptAudio : null}
                    showAvatar={showAvatar}
                    chatBubbleContainerRef={chatBubbleContainerRef}
                    setChatBubbleDone={setTeacherChineseChatBubbleDone}
                    useChineseFont={true}
                />
            </>)}
            {(teacherChineseChatBubbleDone && !isStudentResponsePromptEmpty(dialogPrompt.student_response_prompt)) && (
                <ChatBubble
                    isTeacher={false}
                    message={dialogPrompt.student_response_prompt ? dialogPrompt.student_response_prompt : ""}
                    messageAudio={null} // no audio for student
                    showAvatar={showAvatar}
                    chatBubbleContainerRef={chatBubbleContainerRef}
                    setChatBubbleDone={setStudentChatBubbleDone}
                    useChineseFont={true}
                />
            )}

            {studentAnswerComponentList.map((component, index) => (
                <Box key={index} component="div">
                    {component}
                </Box>
            ))}

            {correctedResponse && studentAnswerDone && (
                <ChatBubble
                    isTeacher={true}
                    message={dialogPrompt.teacher_ask_student_to_speak_prompt ? dialogPrompt.teacher_ask_student_to_speak_prompt : ""}
                    messageAudio={askStudentToSpeakPromptAudio ? askStudentToSpeakPromptAudio : null}
                    showAvatar={showAvatar}
                    chatBubbleContainerRef={chatBubbleContainerRef}
                    setChatBubbleDone={setTeacherSpeakRequestDone}
                />
            )}
            {correctedResponse && teacherSpeakRequestDone && (
                <ChatBubble
                    isTeacher={true}
                    message={correctedResponse ? correctedResponse.closest_sentence_text : ""}
                    messageAudio={closestSentencePromptAudio ? closestSentencePromptAudio : null}
                    showAvatar={showAvatar}
                    chatBubbleContainerRef={chatBubbleContainerRef}
                    setChatBubbleDone={setClosestSentencePromptDone}
                    listenSentenceId={correctedResponse.closest_sentence_id}
                />
            )}

            {sentenceScoreList.map((sentenceScore, index) => (
                <div key={index}>
                    <ChatBubble
                        isTeacher={false}
                        message={sentenceScore.text}
                        messageAudio={null} // audio will be handled through sentence score
                        sentenceScore={sentenceScore}
                        showAvatar={showAvatar}
                        chatBubbleContainerRef={chatBubbleContainerRef}
                        setChatBubbleDone={(done) => setChatBubbleSentenceScoreDone(previous => {
                            console.log("sentene score updated index " + index)
                            const updatedArray = [...previous];
                            updatedArray[index] = done;
                            return updatedArray;
                        })}
                    />
                    {chatBubbleSentenceScoreDone[index] && (<>
                        {sentenceScoreAllGood(sentenceScore) ? (
                            <ChatBubble
                                isTeacher={true}
                                message={dialogPrompt.spoken_yes_response ? dialogPrompt.spoken_yes_response : ""}
                                messageAudio={spokenYesResponseAudio ? spokenYesResponseAudio : null}
                                showAvatar={showAvatar}
                                chatBubbleContainerRef={chatBubbleContainerRef}
                                setChatBubbleDone={setChatBubbleContinueDone}
                            />
                        ) : sentenceScoreAnyFailed(sentenceScore) ? (
                            <ChatBubble isTeacher={dialogPrompt.is_teacher}
                                message={dialogPrompt.spoken_no_response ? dialogPrompt.spoken_no_response : ""}
                                messageAudio={spokenNoResponseAudio ? spokenNoResponseAudio : null}
                                showAvatar={showAvatar}
                                chatBubbleContainerRef={chatBubbleContainerRef}
                                setChatBubbleDone={(done) => {
                                    setChatBubbleTryAgainDone(false);
                                    // set null, to skip this index
                                    setTryAgainClickedList((previous) => [...previous, null]);
                                }}
                            />
                        ) : (
                            <ChatBubble isTeacher={dialogPrompt.is_teacher}
                                message={SentenceScoreMediumResult}
                                messageAudio={sentenceScoreMediumResultAudio ? sentenceScoreMediumResultAudio : null}
                                showAvatar={showAvatar}
                                chatBubbleContainerRef={chatBubbleContainerRef}
                                setChatBubbleDone={(done) => setChatBubbleTeacherResponseDone(previous => {
                                    const updatedArray = [...previous];
                                    updatedArray[index] = done;
                                    return updatedArray;
                                })}
                            />
                        )}
                        {chatBubbleTeacherResponseDone[index] && (
                            <ChatBubble
                                isTeacher={true}
                                message={SentenceScoreTryAgain}
                                messageAudio={sentenceScoreTryAgainAudio ? sentenceScoreTryAgainAudio : null}
                                showAvatar={showAvatar}
                                chatBubbleContainerRef={chatBubbleContainerRef}
                                setChatBubbleDone={setChatBubbleTryAgainDone}
                            />
                        )}
                        {tryAgainClickedList[index] === true && sentenceScoreTryAgainYesAudio != undefined && (
                            <ChatBubble
                                isTeacher={false}
                                message={SentenceScoreTryAgainYes}
                                messageAudio={null} // no audio for student
                                showAvatar={showAvatar}
                                chatBubbleContainerRef={chatBubbleContainerRef}
                            />
                        )}
                        {tryAgainClickedList[index] === false && sentenceScoreTryAgainNoAudio != undefined && (
                            <ChatBubble
                                isTeacher={false}
                                message={SentenceScoreTryAgainNo}
                                messageAudio={null} // no audio for student
                                showAvatar={showAvatar}
                                chatBubbleContainerRef={chatBubbleContainerRef}
                                setChatBubbleDone={setChatBubbleContinueDone}
                            />

                        )}
                    </>)}
                </div>
            ))}
            {/* Confirmation Dialog */}
            <Dialog open={openCancelDialog} onClose={handleCancelDialogCancel}>
                <DialogTitle><Typography variant="h4" sx={{ color: '#383838' }}>Confirm Skip</Typography></DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ color: '#8D8D8D' }}>
                        Are you sure you want to skip this exercise?
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-between', '& .MuiButton-root': { flex: 1 } }}>
                    <Button onClick={handleCancelDialogCancel} color="primary" sx={{ color: '#787878' }}>
                        Keep trying
                    </Button>
                    <Button onClick={handleCancelDialogConfirm} color="primary" sx={{ color: '#787878' }}>
                        Skip
                    </Button>
                </DialogActions>
            </Dialog>
            {/* Add Sentence Confirmation Dialog */}
            <Dialog open={addSentenceDialogOpen} onClose={handleClose}>
                <DialogTitle><Typography variant="h4" sx={{ color: '#383838' }}>Confirmation</Typography></DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ color: '#8D8D8D' }}>
                        Do you want to add this sentence to Self-Study?
                        <p>{studentAnswer}</p>
                    </DialogContentText>
                    {/* Display success message inside the Dialog */}
                    {addSentenceSuccessMessage && <p>{addSentenceSuccessMessage}</p>}
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-between', '& .MuiButton-root': { flex: 1 } }}>
                    <Button onClick={handleClose} color="primary" sx={{ color: '#787878' }}>
                        {addSentenceSuccessMessage.length == 0 ? "Cancel" : "Close"}
                    </Button>
                    <Button onClick={handleConfirm} color="primary" disabled={addSentenceSuccessMessage.length > 0} sx={{ color: '#787878' }}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Stack >
    );
};

export default DialogPromptTypeSpeakComponent;
