import { Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, Stack, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { DialogPlanApi, IDialogPlan } from '../../api/DialogPlanApi';
import { DialogPlanLlmPrompt, DialogPlanLlmPromptApi, DialogPlanLlmPromptConversationListen, DialogPlanLlmPromptConversationListenSpeak, DialogPlanLlmPromptConversationListenWithoutCharacter, DialogPlanLlmPromptConversationReadSpeak, DialogPlanLlmPromptConversationReadWithBlankSpeak, DialogPlanLlmPromptConversationSpeakOnly, DialogPlanLlmPromptFactory, DialogPlanLlmPromptImage, DialogPlanLlmPromptIntroduceWord, DialogPlanLlmPromptListenSpeak, DialogPlanLlmPromptListenSpeakNoGrade, DialogPlanLlmPromptQuizQuestion, DialogPlanLlmPromptQuizQuestionAudio, DialogPlanLlmPromptQuizWord, DialogPlanLlmPromptSpeakNoGrade, DialogPlanLlmPromptTypeSpeak } from '../../api/DialogPlanLlmPromptApi';
import ErrorMessageContext, { handleErrorMessage } from '../../context/ErrorMessageContext';
import { DialogPlanLlmPromptConversationListenForm } from './DialogPlanLlmPromptConversationListenForm';
import { DialogPlanLlmPromptConversationListenSpeakForm } from './DialogPlanLlmPromptConversationListenSpeakForm';
import { DialogPlanLlmPromptConversationListenWithoutCharacterForm } from './DialogPlanLlmPromptConversationListenWithoutCharacterForm';
import { DialogPlanLlmPromptConversationReadSpeakForm } from './DialogPlanLlmPromptConversationReadSpeakForm';
import { DialogPlanLlmPromptConversationReadWithBlankSpeakForm } from './DialogPlanLlmPromptConversationReadWithBlankSpeakForm';
import { DialogPlanLlmPromptConversationSpeakOnlyForm } from './DialogPlanLlmPromptConversationSpeakOnlyForm';
import { DialogPlanLlmPromptQuizQuestionAudioForm } from './DialogPlanLlmPromptQuizQuestionAudioForm';
import { DialogPlanLlmPromptQuizQuestionForm } from './DialogPlanLlmPromptQuizQuestionForm';
import { DialogPlanLlmPromptQuizWordForm } from './DialogPlanLlmPromptQuizWordForm';
import { DialogPlanLlmPromptSpeakNoGradeForm } from './DialogPlanLlmPromptSpeakNoGradeForm';
import { DialogPlanLlmPromptTypeSpeakForm } from './DialogPlanLlmPromptTypeSpeakForm';

interface DialogPlanLlmPromptFormProps {
    dialogPlanLlmPromptId?: number;
    originalDialogPlanLlmPrompt?: DialogPlanLlmPrompt;
    onSubmit?: (updatedDialogPlanLlmPrompt: DialogPlanLlmPrompt, setIsSaving: React.Dispatch<React.SetStateAction<boolean>>) => void;
    onDelete?: (dialogPlanLlmPromptId: number) => void;

    handleLlmPromptChange?: (dialogPlanLlmPrompt: DialogPlanLlmPrompt) => void;
    handleRegenerateDialogPlanLlmPrompt?: (dialogPlanLlmPrompt: DialogPlanLlmPrompt, fieldName: string) => void;
    isRegenerating?: boolean;
}

export function dialogPlanLlmPromptClassNameContainsProp(className: string, prop: string) {
    if (className == null || className.length <= 0) return false;
    // don't need to include all fields, just getting DialogPlanLlmPrompt object
    const selectedDialogPlanLlmPrompt = DialogPlanLlmPromptFactory(
        className,
        -1,
        -1,
        -1,
        true,
        "ERROR-dialogPlanLlmPromptClassNameContainsProp",
        true);
    return prop in selectedDialogPlanLlmPrompt
}

export function getClassNameDescription(className: String) {
    if (className === "DialogPlanLlmPromptModel") return "Dialog Only";
    if (className === "DialogPlanLlmPromptImageModel") return "Dialog w/ Image";
    if (className === "DialogPlanLlmPromptIntroduceWordModel") return "Dialog w/ Character Listen";
    if (className === "DialogPlanLlmPromptListenSpeakModel") return "Dialog w/ Listen & Speak";
    if (className === "DialogPlanLlmPromptListenSpeakNoGradeModel") return "Dialog w/ Listen & Speak Anything, No Grade";

    if (className === "DialogPlanLlmPromptQuizWordModel") return "Quiz: Character Recognition";
    if (className === "DialogPlanLlmPromptQuizQuestionModel") return "Quiz: Select One";
    if (className === "DialogPlanLlmPromptQuizQuestionAudioModel") return "Quiz: Select One Audio";
    if (className === "DialogPlanLlmPromptTypeSpeakModel") return "Prompt, Type, Speak";
    if (className === "DialogPlanLlmPromptSpeakNoGradeModel") return "Prompt, Speak Anything, No Grade";

    if (className === "DialogPlanLlmPromptConversationListenModel") return "Conversation: Listen";
    if (className === "DialogPlanLlmPromptConversationListenSpeakModel") return "Conversation: Listen then Speak";
    if (className === "DialogPlanLlmPromptConversationListenWithoutCharacterModel") return "Conversation: Listen without Character";
    if (className === "DialogPlanLlmPromptConversationReadSpeakModel") return "Conversation: Read then Speak";
    if (className === "DialogPlanLlmPromptConversationReadWithBlankSpeakModel") return "Conversation: Read w/ Blank then Speak";
    if (className === "DialogPlanLlmPromptConversationSpeakOnlyModel") return "Conversation: Speak Only";

    throw new Error("Unknown class name : " + className);
}

const DialogPlanLlmPromptForm: React.FC<DialogPlanLlmPromptFormProps> = ({ dialogPlanLlmPromptId, originalDialogPlanLlmPrompt, onSubmit, onDelete, handleLlmPromptChange, handleRegenerateDialogPlanLlmPrompt, isRegenerating }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();

    const [dialogPlanLlmPrompt, setDialogPlanLlmPrompt] = React.useState<DialogPlanLlmPrompt | null>(null);

    const [isGenerated, setIsGenerated] = React.useState(false);

    // Initial values reset by useEffect below
    const [id, setId] = React.useState(-1);
    const [dialogPlanId, setDialogPlanId] = React.useState(-1);
    const [dialogPlanList, setDialogPlanList] = React.useState<IDialogPlan[]>([]);
    const [className, setClassName] = React.useState('');

    const [isTeacher, setIsTeacher] = React.useState(true);
    const [prompt, setPrompt] = React.useState('');
    const [isNoteToStudent, setIsNoteToStudent] = React.useState(Boolean);
    const [orderIndex, setOrderIndex] = React.useState(-1);

    const [imageSource, setImageSource] = React.useState("");

    const [newWord, setNewWord] = React.useState("");
    const [yesResponse, setYesResponse] = React.useState("");
    const [noResponse, setNoResponse] = React.useState("");

    const [isSaving, setIsSaving] = React.useState(false); // Add saving state

    const [showConfirmation, setShowConfirmation] = React.useState(false);

    React.useEffect(() => {
        if (originalDialogPlanLlmPrompt) {
            setIsGenerated(true)
        } else {
            setIsGenerated(false)
        }

        const fetchDialogList = async () => {
            try {
                const response = await DialogPlanApi.getAll();
                setDialogPlanList(response.data);
            } catch (err: any) {
                handleErrorMessage(err, setErrorMessage, history);
            }
        };
        fetchDialogList();
    }, []);
    React.useEffect(() => {
        if (dialogPlanLlmPromptId && !originalDialogPlanLlmPrompt) {
            DialogPlanLlmPromptApi.getById(dialogPlanLlmPromptId)
                .then((response) => {
                    setDialogPlanLlmPrompt(response.data)
                    setDialogPlanLlmPromptFields(response.data);
                })
                .catch(err => {
                    handleErrorMessage(err, setErrorMessage, history);
                })
        }
    }, [dialogPlanLlmPromptId])
    React.useEffect(() => {
        if (originalDialogPlanLlmPrompt && JSON.stringify(originalDialogPlanLlmPrompt) !== JSON.stringify(dialogPlanLlmPrompt)) {
            setDialogPlanLlmPrompt(originalDialogPlanLlmPrompt);
            setDialogPlanLlmPromptFields(originalDialogPlanLlmPrompt);
        }
    }, [originalDialogPlanLlmPrompt])

    const setDialogPlanLlmPromptFields = (dialogPlanLlmPrompt: DialogPlanLlmPrompt) => {
        setId(dialogPlanLlmPrompt.id);
        setDialogPlanId(dialogPlanLlmPrompt.dialog_plan_id);
        setClassName(dialogPlanLlmPrompt.class_name);

        setIsTeacher(dialogPlanLlmPrompt.is_teacher);
        setPrompt(dialogPlanLlmPrompt.prompt);
        setIsNoteToStudent(dialogPlanLlmPrompt.is_note_to_student ? dialogPlanLlmPrompt.is_note_to_student : false);
        setOrderIndex(dialogPlanLlmPrompt.order_index);

        if ("image_src" in dialogPlanLlmPrompt) {
            setImageSource((dialogPlanLlmPrompt as DialogPlanLlmPromptImage).image_src);
        }
        if ("new_word" in dialogPlanLlmPrompt) {
            setNewWord((dialogPlanLlmPrompt as DialogPlanLlmPromptIntroduceWord).new_word);
        }
        if ("no_response" in dialogPlanLlmPrompt) {
            setNoResponse((dialogPlanLlmPrompt as DialogPlanLlmPromptListenSpeak).no_response)
        }
        if ("yes_response" in dialogPlanLlmPrompt) {
            setYesResponse((dialogPlanLlmPrompt as DialogPlanLlmPromptListenSpeakNoGrade).yes_response)
        }
    }

    // not needed if dialogPrompt given, fields are hidden and not used.
    const handleIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setId(Number(e.target.value));
        if (dialogPlanLlmPrompt) dialogPlanLlmPrompt.id = Number(e.target.value)
    };
    const handleDialogPlanIdChange = (e: SelectChangeEvent<number>) => {
        setDialogPlanId(Number(e.target.value));
        if (dialogPlanLlmPrompt) dialogPlanLlmPrompt.dialog_plan_id = Number(e.target.value)
    };
    const handleClassNameChange = (e: SelectChangeEvent<string>) => {
        setClassName(e.target.value);
        if (dialogPlanLlmPrompt) dialogPlanLlmPrompt.class_name = e.target.value
    };
    const handleOrderIndexChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setOrderIndex(Number(e.target.value));
        if (dialogPlanLlmPrompt) dialogPlanLlmPrompt.order_index = Number(e.target.value)
    };

    // update dialog prompt
    const handleIsTeacherChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsTeacher(e.target.value === 'true');
        if (dialogPlanLlmPrompt) dialogPlanLlmPrompt.is_teacher = e.target.value === 'true'
    };
    const handlePromptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPrompt(e.target.value);
        if (dialogPlanLlmPrompt) dialogPlanLlmPrompt.prompt = e.target.value
    };
    const handleIsNoteToStudentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsNoteToStudent(e.target.checked);
        if (dialogPlanLlmPrompt) dialogPlanLlmPrompt.is_note_to_student = e.target.checked
    };
    const handleImageSourceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setImageSource(e.target.value);
        if (dialogPlanLlmPrompt) (dialogPlanLlmPrompt as DialogPlanLlmPromptImage).image_src = e.target.value
    };
    const handleNewWordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewWord(e.target.value);
        if (dialogPlanLlmPrompt) (dialogPlanLlmPrompt as DialogPlanLlmPromptIntroduceWord).new_word = e.target.value
    };

    const handleYesResponseChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setYesResponse(e.target.value);
        if (dialogPlanLlmPrompt) (dialogPlanLlmPrompt as DialogPlanLlmPromptListenSpeakNoGrade).yes_response = e.target.value
    };
    const handleNoResponseChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNoResponse(e.target.value);
        if (dialogPlanLlmPrompt) (dialogPlanLlmPrompt as DialogPlanLlmPromptListenSpeak).no_response = e.target.value
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSaving(true); // Set saving state to true

        if (dialogPlanLlmPrompt != null) {
            onSubmit && onSubmit(dialogPlanLlmPrompt, setIsSaving);
        }

    }

    const handleDeleteConfirmation = () => {
        setShowConfirmation(true);
    };

    const handleDeleteCancel = () => {
        setShowConfirmation(false);
    };

    const handleDeleteConfirm = () => {
        setShowConfirmation(false);
        onDelete && dialogPlanLlmPromptId && onDelete(dialogPlanLlmPromptId);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Stack spacing={4} sx={{ width: '90%', margin: '0 auto', pt: 2, pb: 4 }}>
                <Divider sx={{ borderColor: '#FFBE19', borderWidth: '2px', mb: 2 }} />  {/* Customized Divider */}

                {dialogPlanLlmPromptId && <>
                    {/* hide these fields if generated prompt exists */}
                    <input type="hidden" value={id} onChange={handleIdChange} />
                    <input type="hidden" value={dialogPlanId} onChange={handleDialogPlanIdChange} />
                    <FormControl>
                        <InputLabel id="class-name-label">Class Name</InputLabel>
                        <Select
                            labelId="class-name-label"
                            id="class-name-select"
                            value={className}
                            onChange={handleClassNameChange}
                            required
                        >

                            <MenuItem value="DialogPlanLlmPromptModel">{getClassNameDescription("DialogPlanLlmPromptModel")}</MenuItem>
                            <MenuItem value="DialogPlanLlmPromptImageModel">{getClassNameDescription("DialogPlanLlmPromptImageModel")}</MenuItem>
                            <MenuItem value="DialogPlanLlmPromptIntroduceWordModel">{getClassNameDescription("DialogPlanLlmPromptIntroduceWordModel")}</MenuItem>
                            <MenuItem value="DialogPlanLlmPromptListenSpeakModel">{getClassNameDescription("DialogPlanLlmPromptListenSpeakModel")}</MenuItem>
                            <MenuItem value="DialogPlanLlmPromptListenSpeakNoGradeModel">{getClassNameDescription("DialogPlanLlmPromptListenSpeakNoGradeModel")}</MenuItem>

                            <MenuItem value="DialogPlanLlmPromptQuizWordModel">{getClassNameDescription("DialogPlanLlmPromptQuizWordModel")}</MenuItem>
                            <MenuItem value="DialogPlanLlmPromptQuizQuestionModel">{getClassNameDescription("DialogPlanLlmPromptQuizQuestionModel")}</MenuItem>
                            <MenuItem value="DialogPlanLlmPromptQuizQuestionAudioModel">{getClassNameDescription("DialogPlanLlmPromptQuizQuestionAudioModel")}</MenuItem>
                            <MenuItem value="DialogPlanLlmPromptTypeSpeakModel">{getClassNameDescription("DialogPlanLlmPromptTypeSpeakModel")}</MenuItem>
                            <MenuItem value="DialogPlanLlmPromptSpeakNoGradeModel">{getClassNameDescription("DialogPlanLlmPromptSpeakNoGradeModel")}</MenuItem>

                            <MenuItem value="DialogPlanLlmPromptConversationListenModel">{getClassNameDescription("DialogPlanLlmPromptConversationListenModel")}</MenuItem>
                            <MenuItem value="DialogPlanLlmPromptConversationListenSpeakModel">{getClassNameDescription("DialogPlanLlmPromptConversationListenSpeakModel")}</MenuItem>
                            <MenuItem value="DialogPlanLlmPromptConversationListenWithoutCharacterModel">{getClassNameDescription("DialogPlanLlmPromptConversationListenWithoutCharacterModel")}</MenuItem>
                            <MenuItem value="DialogPlanLlmPromptConversationReadSpeakModel">{getClassNameDescription("DialogPlanLlmPromptConversationReadSpeakModel")}</MenuItem>
                            <MenuItem value="DialogPlanLlmPromptConversationReadWithBlankSpeakModel">{getClassNameDescription("DialogPlanLlmPromptConversationReadWithBlankSpeakModel")}</MenuItem>
                            <MenuItem value="DialogPlanLlmPromptConversationSpeakOnlyModel">{getClassNameDescription("DialogPlanLlmPromptConversationSpeakOnlyModel")}</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Parent Dialog Plan Name</InputLabel>
                        <Select
                            value={dialogPlanId}
                            label="Parent Dialog Name"
                            onChange={handleDialogPlanIdChange}
                            required
                        >
                            <MenuItem key={-1} value={-1} />
                            {dialogPlanList.map((dialogPlan) => (
                                <MenuItem key={dialogPlan.id} value={dialogPlan.id}>
                                    {dialogPlan.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </>}
                <FormControl component="fieldset">
                    <RadioGroup
                        row
                        aria-label="isTeacher"
                        name="isTeacher"
                        value={isTeacher.toString()}
                        onChange={handleIsTeacherChange}
                    >
                        <FormControlLabel value="true" control={<Radio required />} label="Teacher" />
                        <FormControlLabel value="false" control={<Radio required />} label="Student" />
                    </RadioGroup>
                </FormControl>
                <div>
                    <TextField
                        label="LLM Prompt"
                        value={prompt}
                        onChange={handlePromptChange}
                        multiline
                        rows={5}
                        sx={{ width: '100%', mt: 2 }}
                        inputProps={{
                            style: {
                                resize: 'vertical',
                                overflow: 'hidden',
                            },
                        }}
                    />
                    {(handleRegenerateDialogPlanLlmPrompt && dialogPlanLlmPrompt) && <>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            <Button variant="contained"
                                onClick={() => handleRegenerateDialogPlanLlmPrompt(dialogPlanLlmPrompt, "prompt")}
                                disabled={isRegenerating}>
                                Regenerate
                                {isRegenerating && <CircularProgress size={16} sx={{ ml: 2 }} />}
                            </Button>
                        </Box>
                    </>}
                    <FormControlLabel
                        control={<Checkbox checked={isNoteToStudent} onChange={handleIsNoteToStudentChange} />}
                        label="Is a Note to the Student"
                    />
                    {!isGenerated && <>
                        {/* hide these fields if generated prompt exists */}
                        <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                            &lt;VocabularyWord1/&gt; will be replaced with Vocabulary Word Input when generating. <br />
                            Also &lt;VocabularyWord2/&gt;, &lt;VocabularyWord3/&gt;, &lt;VocabularyWord4/&gt;, &lt;VocabularyWord5/&gt;
                        </Typography>
                        <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                            &lt;HskLevel/&gt; with the number provided, the number only 1-6
                        </Typography>

                        <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                            &lt;StudentName/&gt; will be replaced with the student's name
                        </Typography>
                        <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                            &lt;TeacherName/&gt; will be replaced with the teacher's name
                        </Typography>
                        <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                            &lt;Microphone/&gt; will be replaced with the microphone icon for recording
                        </Typography>
                        <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                            &lt;Speaker/&gt; will be replaced with the speaker icon for listening
                        </Typography>
                        <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                            &lt;Info/&gt; will be replaced with the info icon for more details
                        </Typography>
                    </>}
                </div>

                {dialogPlanLlmPromptClassNameContainsProp(className, "image_src") && (<>
                    <TextField
                        label="LLM Prompt for Image"
                        value={imageSource}
                        onChange={handleImageSourceChange}
                        multiline
                        rows={5}
                        sx={{ width: '100%' }}
                        inputProps={{
                            style: {
                                resize: 'vertical',
                                overflow: 'hidden',
                            },
                        }}
                    />
                    {(handleRegenerateDialogPlanLlmPrompt && dialogPlanLlmPrompt) && <>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            <Button variant="contained"
                                onClick={() => handleRegenerateDialogPlanLlmPrompt(dialogPlanLlmPrompt, "image_src")}
                                disabled={isRegenerating}>
                                Regenerate
                                {isRegenerating && <CircularProgress size={16} sx={{ ml: 2 }} />}
                            </Button>
                        </Box>
                    </>}
                    {/* spacer for image on the right */}
                    <Box sx={{ height: '151px', marginTop: '0px !important' }} />
                </>)}

                {dialogPlanLlmPromptClassNameContainsProp(className, "new_word") && (
                    <TextField
                        label="New Word"
                        value={newWord}
                        onChange={handleNewWordChange}
                        required
                    />
                )}

                {dialogPlanLlmPromptClassNameContainsProp(className, "yes_response") && (<>
                    <TextField
                        label="LLM Prompt for Yes Response"
                        value={yesResponse}
                        onChange={handleYesResponseChange}
                        multiline
                        rows={5}
                        required
                    />
                    {(handleRegenerateDialogPlanLlmPrompt && dialogPlanLlmPrompt) && <>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            <Button variant="contained"
                                onClick={() => handleRegenerateDialogPlanLlmPrompt(dialogPlanLlmPrompt, "yes_response")}
                                disabled={isRegenerating}>
                                Regenerate
                                {isRegenerating && <CircularProgress size={16} sx={{ ml: 2 }} />}
                            </Button>
                        </Box>
                    </>}
                </>)}
                {dialogPlanLlmPromptClassNameContainsProp(className, "no_response") && (<>
                    <TextField
                        label="LLM Prompt for No Response"
                        value={noResponse}
                        onChange={handleNoResponseChange}
                        multiline
                        rows={5}
                        required
                    />
                    {(handleRegenerateDialogPlanLlmPrompt && dialogPlanLlmPrompt) && <>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            <Button variant="contained"
                                onClick={() => handleRegenerateDialogPlanLlmPrompt(dialogPlanLlmPrompt, "no_response")}
                                disabled={isRegenerating}>
                                Regenerate
                                {isRegenerating && <CircularProgress size={16} sx={{ ml: 2 }} />}
                            </Button>
                        </Box>
                    </>}
                </>)}
                {className == "DialogPlanLlmPromptQuizWordModel" &&
                    <DialogPlanLlmPromptQuizWordForm dialogPlanLlmPromptId={dialogPlanLlmPromptId}
                        dialogPlanLlmPrompt={dialogPlanLlmPromptId ? undefined : (dialogPlanLlmPrompt as DialogPlanLlmPromptQuizWord)}
                        setDialogPlanLlmPrompt={setDialogPlanLlmPrompt} handleLlmPromptChange={handleLlmPromptChange}
                        handleRegenerateDialogPlanLlmPrompt={handleRegenerateDialogPlanLlmPrompt} isRegenerating={isRegenerating}
                        id={id} dialogPlanId={dialogPlanId} orderIndex={orderIndex} isTeacher={isTeacher} prompt={prompt}
                        isNoteToStudent={isNoteToStudent}
                        newWord={newWord} yesResponse={yesResponse} noResponse={noResponse} />}
                {className == "DialogPlanLlmPromptQuizQuestionModel" &&
                    <DialogPlanLlmPromptQuizQuestionForm dialogPlanLlmPromptId={dialogPlanLlmPromptId}
                        dialogPlanLlmPrompt={dialogPlanLlmPromptId ? undefined : (dialogPlanLlmPrompt as DialogPlanLlmPromptQuizQuestion)}
                        setDialogPlanLlmPrompt={setDialogPlanLlmPrompt} handleLlmPromptChange={handleLlmPromptChange}
                        handleRegenerateDialogPlanLlmPrompt={handleRegenerateDialogPlanLlmPrompt} isRegenerating={isRegenerating}
                        id={id} dialogPlanId={dialogPlanId} orderIndex={orderIndex} isTeacher={isTeacher} prompt={prompt}
                        isNoteToStudent={isNoteToStudent} yesResponse={yesResponse} noResponse={noResponse} imageSource={imageSource} />}
                {className == "DialogPlanLlmPromptQuizQuestionAudioModel" &&
                    <DialogPlanLlmPromptQuizQuestionAudioForm dialogPlanLlmPromptId={dialogPlanLlmPromptId}
                        dialogPlanLlmPrompt={dialogPlanLlmPromptId ? undefined : (dialogPlanLlmPrompt as DialogPlanLlmPromptQuizQuestionAudio)}
                        setDialogPlanLlmPrompt={setDialogPlanLlmPrompt} handleLlmPromptChange={handleLlmPromptChange}
                        handleRegenerateDialogPlanLlmPrompt={handleRegenerateDialogPlanLlmPrompt} isRegenerating={isRegenerating}
                        id={id} dialogPlanId={dialogPlanId} orderIndex={orderIndex} isTeacher={isTeacher} prompt={prompt}
                        isNoteToStudent={isNoteToStudent} yesResponse={yesResponse} noResponse={noResponse} imageSource={imageSource} />}
                {className == "DialogPlanLlmPromptTypeSpeakModel" &&
                    <DialogPlanLlmPromptTypeSpeakForm dialogPlanLlmPromptId={dialogPlanLlmPromptId}
                        dialogPlanLlmPrompt={dialogPlanLlmPromptId ? undefined : (dialogPlanLlmPrompt as DialogPlanLlmPromptTypeSpeak)}
                        setDialogPlanLlmPrompt={setDialogPlanLlmPrompt} handleLlmPromptChange={handleLlmPromptChange}
                        handleRegenerateDialogPlanLlmPrompt={handleRegenerateDialogPlanLlmPrompt} isRegenerating={isRegenerating}
                        id={id} dialogPlanId={dialogPlanId} orderIndex={orderIndex} isTeacher={isTeacher} prompt={prompt}
                        isNoteToStudent={isNoteToStudent} yesResponse={yesResponse} imageSource={imageSource} />}
                {className == "DialogPlanLlmPromptSpeakNoGradeModel" &&
                    <DialogPlanLlmPromptSpeakNoGradeForm dialogPlanLlmPromptId={dialogPlanLlmPromptId}
                        dialogPlanLlmPrompt={dialogPlanLlmPromptId ? undefined : (dialogPlanLlmPrompt as DialogPlanLlmPromptSpeakNoGrade)}
                        setDialogPlanLlmPrompt={setDialogPlanLlmPrompt} handleLlmPromptChange={handleLlmPromptChange}
                        handleRegenerateDialogPlanLlmPrompt={handleRegenerateDialogPlanLlmPrompt} isRegenerating={isRegenerating}
                        id={id} dialogPlanId={dialogPlanId} orderIndex={orderIndex} isTeacher={isTeacher} prompt={prompt}
                        isNoteToStudent={isNoteToStudent} yesResponse={yesResponse} imageSource={imageSource} />}
                {className == "DialogPlanLlmPromptConversationListenModel" &&
                    <DialogPlanLlmPromptConversationListenForm dialogPlanLlmPromptId={dialogPlanLlmPromptId}
                        dialogPlanLlmPrompt={dialogPlanLlmPromptId ? undefined : (dialogPlanLlmPrompt as DialogPlanLlmPromptConversationListen)}
                        setDialogPlanLlmPrompt={setDialogPlanLlmPrompt} handleLlmPromptChange={handleLlmPromptChange}
                        handleRegenerateDialogPlanLlmPrompt={handleRegenerateDialogPlanLlmPrompt} isRegenerating={isRegenerating}
                        id={id} dialogPlanId={dialogPlanId} orderIndex={orderIndex} isTeacher={isTeacher} prompt={prompt}
                        isNoteToStudent={isNoteToStudent} />}
                {className == "DialogPlanLlmPromptConversationListenWithoutCharacterModel" &&
                    <DialogPlanLlmPromptConversationListenWithoutCharacterForm dialogPlanLlmPromptId={dialogPlanLlmPromptId}
                        dialogPlanLlmPrompt={dialogPlanLlmPromptId ? undefined : (dialogPlanLlmPrompt as DialogPlanLlmPromptConversationListenWithoutCharacter)}
                        setDialogPlanLlmPrompt={setDialogPlanLlmPrompt} handleLlmPromptChange={handleLlmPromptChange}
                        handleRegenerateDialogPlanLlmPrompt={handleRegenerateDialogPlanLlmPrompt} isRegenerating={isRegenerating}
                        id={id} dialogPlanId={dialogPlanId} orderIndex={orderIndex} isTeacher={isTeacher} prompt={prompt}
                        isNoteToStudent={isNoteToStudent} />}
                {className == "DialogPlanLlmPromptConversationListenSpeakModel" &&
                    <DialogPlanLlmPromptConversationListenSpeakForm dialogPlanLlmPromptId={dialogPlanLlmPromptId}
                        dialogPlanLlmPrompt={dialogPlanLlmPromptId ? undefined : (dialogPlanLlmPrompt as DialogPlanLlmPromptConversationListenSpeak)}
                        setDialogPlanLlmPrompt={setDialogPlanLlmPrompt} handleLlmPromptChange={handleLlmPromptChange}
                        handleRegenerateDialogPlanLlmPrompt={handleRegenerateDialogPlanLlmPrompt} isRegenerating={isRegenerating}
                        id={id} dialogPlanId={dialogPlanId} orderIndex={orderIndex} isTeacher={isTeacher} prompt={prompt}
                        isNoteToStudent={isNoteToStudent} yesResponse={yesResponse} noResponse={noResponse} />}
                {className == "DialogPlanLlmPromptConversationReadSpeakModel" &&
                    <DialogPlanLlmPromptConversationReadSpeakForm dialogPlanLlmPromptId={dialogPlanLlmPromptId}
                        dialogPlanLlmPrompt={dialogPlanLlmPromptId ? undefined : (dialogPlanLlmPrompt as DialogPlanLlmPromptConversationReadSpeak)}
                        setDialogPlanLlmPrompt={setDialogPlanLlmPrompt} handleLlmPromptChange={handleLlmPromptChange}
                        handleRegenerateDialogPlanLlmPrompt={handleRegenerateDialogPlanLlmPrompt} isRegenerating={isRegenerating}
                        id={id} dialogPlanId={dialogPlanId} orderIndex={orderIndex} isTeacher={isTeacher} prompt={prompt}
                        isNoteToStudent={isNoteToStudent} yesResponse={yesResponse} noResponse={noResponse} />}
                {className == "DialogPlanLlmPromptConversationSpeakOnlyModel" &&
                    <DialogPlanLlmPromptConversationSpeakOnlyForm dialogPlanLlmPromptId={dialogPlanLlmPromptId}
                        dialogPlanLlmPrompt={dialogPlanLlmPromptId ? undefined : (dialogPlanLlmPrompt as DialogPlanLlmPromptConversationSpeakOnly)}
                        setDialogPlanLlmPrompt={setDialogPlanLlmPrompt} handleLlmPromptChange={handleLlmPromptChange}
                        handleRegenerateDialogPlanLlmPrompt={handleRegenerateDialogPlanLlmPrompt} isRegenerating={isRegenerating}
                        id={id} dialogPlanId={dialogPlanId} orderIndex={orderIndex} isTeacher={isTeacher} prompt={prompt}
                        isNoteToStudent={isNoteToStudent} yesResponse={yesResponse} noResponse={noResponse} />}
                {className == "DialogPlanLlmPromptConversationReadWithBlankSpeakModel" &&
                    <DialogPlanLlmPromptConversationReadWithBlankSpeakForm dialogPlanLlmPromptId={dialogPlanLlmPromptId}
                        dialogPlanLlmPrompt={dialogPlanLlmPromptId ? undefined : (dialogPlanLlmPrompt as DialogPlanLlmPromptConversationReadWithBlankSpeak)}
                        setDialogPlanLlmPrompt={setDialogPlanLlmPrompt} handleLlmPromptChange={handleLlmPromptChange}
                        handleRegenerateDialogPlanLlmPrompt={handleRegenerateDialogPlanLlmPrompt} isRegenerating={isRegenerating}
                        id={id} dialogPlanId={dialogPlanId} orderIndex={orderIndex} isTeacher={isTeacher} prompt={prompt}
                        isNoteToStudent={isNoteToStudent} yesResponse={yesResponse} noResponse={noResponse} />}
                {!isGenerated && <>
                    {/* hide these fields if generated prompt exists */}

                    <TextField
                        label="Sequence Number"
                        value={orderIndex}
                        onChange={handleOrderIndexChange}
                        type="number"
                        required
                    />
                    <br />
                    <Button disabled={isSaving} type="submit" variant="contained">
                        {isSaving ? <CircularProgress size={24} /> : 'Save'}
                    </Button>
                    <Button variant="contained" color="error" onClick={handleDeleteConfirmation}>
                        Delete
                    </Button>
                </>}
            </Stack>
            <Dialog open={showConfirmation} onClose={handleDeleteCancel}>
                <DialogTitle>Delete DialogPlanLlmPrompt</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to delete this dialogPlanLlmPrompt?</Typography>
                    <Typography>{prompt}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel}>Cancel</Button>
                    <Button onClick={handleDeleteConfirm} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </form>
    );
};

export default DialogPlanLlmPromptForm;