import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import { DialogPromptSpeakNoGrade } from '../../api/DialogPromptApi';
import ChatBubble from '../ChatBubble';

import MicrophoneBlueIcon from '../../asset/icon-microphone-blue.png';
import RightArrowBlueIcon from '../../asset/icon-right-arrow-blue.png';
import SpeakerBlueIcon from '../../asset/icon-speaker-blue.svg';
import AudioWaveIcon from '../../asset/icons8-audio-wave.gif';

import { useHistory } from 'react-router-dom';
import { AudioApi } from '../../api/AudioApi';
import { ISentence, SentenceApi } from '../../api/SentenceApi';
import ErrorMessageContext, { handleErrorMessage } from '../../context/ErrorMessageContext';
import { getAudioByText } from './DialogPromptComponent';

interface DialogPromptSpeakNoGradeComponentProps {
    dialogPrompt: DialogPromptSpeakNoGrade;
    setDialogPromptComplete: (dialogPromptId: number) => void;
    setPromptInputComponent: (component: React.ReactNode | null) => void;
    showAvatar?: boolean;
    chatBubbleContainerRef: React.RefObject<HTMLDivElement>;
    isSettings?: boolean;
}


const DialogPromptSpeakNoGradeComponent: React.FC<DialogPromptSpeakNoGradeComponentProps> = ({ dialogPrompt, setDialogPromptComplete, setPromptInputComponent, showAvatar = true, chatBubbleContainerRef, isSettings = false }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();
    const [teacherChinesePromptSentence, setTeacherChinesePromptSentence] = React.useState<ISentence | null>(null);

    const [promptAudio, setPromptAudio] = React.useState<Blob | null | undefined>(undefined);
    const [teacherChinesePromptAudio, setTeacherChinesePromptAudio] = React.useState<Blob | null | undefined>(undefined);
    const [studentResponsePromptAudio, setStudentResponsePromptAudio] = React.useState<Blob | null | undefined>(undefined);
    const [yesResponseAudio, setYesResponseAudio] = React.useState<Blob | null | undefined>(undefined);

    React.useEffect(() => {
        if (dialogPrompt.teacher_chinese_prompt_sentence_id) {
            SentenceApi.getById(dialogPrompt.teacher_chinese_prompt_sentence_id)
                .then((response) => {
                    setTeacherChinesePromptSentence(response.data)
                })
                .catch(err => {
                    handleErrorMessage(err, setErrorMessage, history);
                })
        }
        getAudioByText(history, setErrorMessage, dialogPrompt.prompt, setPromptAudio)
        getAudioByText(history, setErrorMessage, dialogPrompt.teacher_chinese_prompt, setTeacherChinesePromptAudio)
        getAudioByText(history, setErrorMessage, dialogPrompt.student_response_prompt, setStudentResponsePromptAudio)
        getAudioByText(history, setErrorMessage, dialogPrompt.yes_response, setYesResponseAudio)

    }, [])

    const [teacherEnglishChatBubbleDone, setTeacherEnglishChatBubbleDone] = React.useState(false);
    const [teacherImageDone, setTeacherImageDone] = React.useState(false);

    const imageRef = React.useRef<HTMLImageElement>(null);

    React.useEffect(() => {
        if (teacherEnglishChatBubbleDone) {
            console.log("teacherEnglishChatBubbleDone true")
            // console.log("teacherEnglishChatBubbleDone=true");
            if ((dialogPrompt.image_src && dialogPrompt.image_src != "broken") || (dialogPrompt.image_src_url && dialogPrompt.image_src_url != "broken")) {
                // console.log("dialogPrompt.image_src")
                // make sure to scroll after the image is loaded.
                const handleImageLoad = () => {
                    setTeacherImageDone(true);
                };

                if (imageRef.current) {
                    if (imageRef.current.complete && imageRef.current.naturalHeight !== 0) {
                        // If image is already loaded
                        handleImageLoad();
                    } else {
                        // Image is not yet loaded, attach event listener
                        imageRef.current.addEventListener('load', handleImageLoad);
                    }
                }

                return () => {
                    // console.log("dialogPrompt.image_src return")
                    if (imageRef.current) {
                        // console.log("dialogPrompt.image_src return removeEventListener")
                        imageRef.current.removeEventListener('load', handleImageLoad);
                    }
                };
            } else {
                console.log("setTeacherImageDone(true)")
                setTeacherImageDone(true);
            }
        }
    }, [teacherEnglishChatBubbleDone, dialogPrompt.image_src, dialogPrompt.image_src_url])


    const [teacherChineseChatBubbleDone, setTeacherChineseChatBubbleDone] = React.useState(false);

    React.useEffect(() => {
        if (teacherImageDone) {
            console.log("teacherImageDone true")
            // console.log("teacherImageDone=true");
            if (teacherChinesePromptSentence && teacherChinesePromptSentence.text) {
                setPromptInputComponent(
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <IconButton onClick={handleTeacherChinesePromptSpeakerClick}>
                            <img src={SpeakerBlueIcon} style={{ width: '36px', height: '36px' }} />
                        </IconButton>
                    </div>
                )
            } else {
                setTeacherChineseChatBubbleDone(true)
            }
        }
    }, [teacherImageDone])

    const handleTeacherChinesePromptSpeakerClick = () => {
        if (teacherChinesePromptSentence == null) return;

        const teacher = localStorage.getItem('teacher');
        let audioPromise;

        if (teacher == "male") {
            audioPromise = AudioApi.getMale(teacherChinesePromptSentence.id)
        } else if (teacher == 'female') {
            audioPromise = AudioApi.getFemale(teacherChinesePromptSentence.id)
        } else if (teacher == 'female2') {
            audioPromise = AudioApi.getFemale2(teacherChinesePromptSentence.id)
        } else {
            throw Error("unknown selected teacher name in local storage " + teacher);
        }

        audioPromise
            .then(response => {
                // check if the response was successful
                if (response.status >= 300) {
                    throw new Error('Getting male audio was not ok statuscode=' + response.status + " " + response.statusText);
                }
                return response.data;
            })
            .then(blob => {
                // create a new Audio object and set the source to the blob URL
                const audio = new Audio(URL.createObjectURL(blob));
                audio.addEventListener('ended', () => { setTeacherChineseChatBubbleDone(true) }); // Listen to the 'ended' event
                audio.play();
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history);
            })
    };

    function isStudentResponsePromptEmpty(prompt: string | null) {
        return prompt == null || prompt == undefined || prompt.trim().toLowerCase() == "<answer/>"
    }

    React.useEffect(() => {
        if (teacherChineseChatBubbleDone) {
            console.log("teacherChineseChatBubbleDone true")
            if (isStudentResponsePromptEmpty(dialogPrompt.student_response_prompt)) {
                // skip student chat bubble if there is none
                setStudentChatBubbleDone(true);
            }
        }
    }, [teacherChineseChatBubbleDone])

    const [studentChatBubbleDone, setStudentChatBubbleDone] = React.useState(false);

    React.useEffect(() => {
        if (studentChatBubbleDone) {
            // console.log("studentChatBubbleDone=true");
            // need to scroll after this effect for some reason.
            // scrolling didn't work after teacherChineseChatBubbleDone
            if (imageRef.current) {
                // console.log("studentChatBubbleDone imageRef scrollIntoView")
                imageRef.current.scrollIntoView({ behavior: 'smooth' });
            }
            if (chatBubbleContainerRef.current) {
                chatBubbleContainerRef.current.scrollIntoView({ behavior: 'smooth' });
                // console.log("studentChatBubbleDone, chatBubbleContainerRef, scrollIntoView")
            }
        }
    }, [studentChatBubbleDone])


    const [isRecording, setIsRecording] = React.useState(false);
    const [studentAudioDone, setStudentAudioDone] = React.useState(false);
    const [chatBubbleContinueDone, setChatBubbleContinueDone] = React.useState(false);

    React.useEffect(() => {
        if (studentChatBubbleDone) {
            console.log("studentChatBubbleDone true")
            setPromptInputComponent(
                <Stack direction="row" alignItems="center" justifyContent="center">
                    {/* Left-aligned X icon */}
                    <IconButton onClick={handleCancelClick}>
                        <img src={RightArrowBlueIcon} style={{ width: '36px', height: '36px' }} />
                    </IconButton>
                    <Stack direction="row" alignItems="center" sx={{ justifyContent: 'center', flexGrow: 1 }} spacing={2}>
                        {/* <IconButton onClick={handleSpeakerClick}>
                            <VolumeUpRoundedIcon fontSize="large" />
                        </IconButton> */}
                        <IconButton onClick={isRecording ? stopRecording : startRecording}>
                            {isRecording ? <img src={AudioWaveIcon} alt="Recording" style={{ width: '32px', height: '32px' }} />
                                : <img src={MicrophoneBlueIcon} style={{ width: '36px', height: '36px' }} />}
                        </IconButton>
                    </Stack>
                </Stack>
            )
        }
    }, [studentChatBubbleDone, isRecording]);

    const mediaRecorderRef = React.useRef<MediaRecorder | null>(null);

    let audioData: Blob | null = null;


    const startRecording = () => {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream) => {
                // console.log("startRecording() 1")
                mediaRecorderRef.current = new MediaRecorder(stream);
                mediaRecorderRef.current.addEventListener('dataavailable', handleAudioDataAvailable);
                mediaRecorderRef.current.addEventListener('stop', handleAudioDone);

                mediaRecorderRef.current.start();

                setIsRecording(true);
                // console.log("startRecording() 2")
            })
            .catch(err=> {
                handleErrorMessage(err, setErrorMessage, history);
            });
    };
    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            // console.log("stopRecording() 1")
            mediaRecorderRef.current.stop();
            mediaRecorderRef.current.removeEventListener('dataavailable', handleAudioDataAvailable);
            mediaRecorderRef.current.removeEventListener('stop', handleAudioDone);
            // console.log("stopRecording() 2")
        }

        setIsRecording(false);
    };

    const handleAudioDataAvailable = (event: BlobEvent) => {
        // console.log("handleAudioDataAvailable() 1")
        if (event.data.size > 0) {
            // console.log("event.data.size > 0")
            audioData = event.data;
        }
    };

    const handleAudioDone = () => {
        // console.log("handleAudioDone() 1")
        if (audioData) {
            setStudentAudioDone(true);
            // @TODO save audio somehow. in addition to endpoint, also need need new DB tables I think


            // console.log("handleAudioDone() has audio data 1")
            // setIsScoring(true)
            // const formData = new FormData();
            // formData.append('audio', audioData);

            // console.log("handle audio done studentCorrectResponseSentenceId " + studentCorrectResponseSentenceId)

            // ScoreApi.getScore(studentCorrectResponseSentenceId, formData)
            //     .then(response => {
            //         setIsScoring(false)
            //         // check if the response was successful
            //         if (response.status >= 300) {
            //             throw new Error('Scoring audio was not ok statuscode=' + response.status + " " + response.statusText);
            //         }
            //         return response.data;
            //     })
            //     .then(responseJson => {
            //         responseJson.audioData = audioData;
            //         // setSentenceScoreList((previous) => [...previous, responseJson]);
            //     })
            //     .catch(err => {
            //         if (err.response && err.response.status === 401) {
            //                             extractErrorMessage(err, setErrorMessage, history);

            //         } else {
            //             console.error(err);
            //             extractErrorMessage(err, setErrorMessage, history);
            //         }
            //     })
        }
    }

    const [openCancelDialog, setOpenCancelDialog] = React.useState(false);

    const handleCancelClick = () => {
        setOpenCancelDialog(true);
    };

    const handleCancelDialogConfirm = () => {
        setOpenCancelDialog(false);
        setChatBubbleContinueDone(true);
    };

    const handleCancelDialogCancel = () => {
        setOpenCancelDialog(false);
    };

    React.useEffect(() => {
        if (chatBubbleContinueDone) {
            setPromptInputComponent(<div />)
            setDialogPromptComplete(dialogPrompt.id)

        }
    }, [chatBubbleContinueDone])

    return (
        <Stack>
            <ChatBubble
                isTeacher={dialogPrompt.is_teacher}
                message={dialogPrompt.prompt}
                messageAudio={promptAudio ? promptAudio : null}
                showAvatar={showAvatar}
                chatBubbleContainerRef={chatBubbleContainerRef}
                setChatBubbleDone={setTeacherEnglishChatBubbleDone}
            />
            {(teacherEnglishChatBubbleDone && (dialogPrompt.image_src || dialogPrompt.image_src_url)) && (<>
                <img
                    ref={imageRef}
                    src={dialogPrompt.image_src ? dialogPrompt.image_src : (dialogPrompt.image_src_url ? dialogPrompt.image_src_url : undefined)}
                    alt={dialogPrompt.teacher_chinese_prompt ? dialogPrompt.teacher_chinese_prompt : ""}
                    style={{
                        maxHeight: '200px', maxWidth: '200px',
                        height: 'auto', width: 'auto',
                        margin: '0 auto', // Add this line to center the image horizontally
                        display: 'block', // Add this line to ensure the margin works correctly
                    }}
                />
            </>)}
            {(teacherImageDone && teacherChinesePromptSentence && teacherChinesePromptSentence.text) && (<>
                <ChatBubble
                    isTeacher={true}
                    message={teacherChinesePromptSentence && teacherChinesePromptSentence.text ? teacherChinesePromptSentence.text : ''}
                    messageAudio={teacherChinesePromptAudio ? teacherChinesePromptAudio : null}
                    showAvatar={showAvatar}
                    chatBubbleContainerRef={chatBubbleContainerRef}
                    setChatBubbleDone={setTeacherChineseChatBubbleDone}
                    useChineseFont={true}
                />
            </>)}
            {(teacherChineseChatBubbleDone && !isStudentResponsePromptEmpty(dialogPrompt.student_response_prompt) && studentResponsePromptAudio != undefined) && (
                <ChatBubble
                    isTeacher={false}
                    message={dialogPrompt.student_response_prompt ? dialogPrompt.student_response_prompt : ""}
                    messageAudio={null} // no audio for student
                    showAvatar={showAvatar}
                    chatBubbleContainerRef={chatBubbleContainerRef}
                    setChatBubbleDone={setStudentChatBubbleDone}
                    useChineseFont={true}
                />
            )}
            {studentAudioDone && (
                <ChatBubble
                    isTeacher={true}
                    message={dialogPrompt.yes_response}
                    messageAudio={yesResponseAudio ? yesResponseAudio : null}
                    showAvatar={showAvatar}
                    chatBubbleContainerRef={chatBubbleContainerRef}
                    setChatBubbleDone={setChatBubbleContinueDone}
                />
            )}

            {/* Confirmation Dialog */}
            <Dialog open={openCancelDialog} onClose={handleCancelDialogCancel}>
                <DialogTitle><Typography variant="h4" sx={{ color: '#383838' }}>Confirm Skip</Typography></DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ color: '#8D8D8D' }}>
                        Are you sure you want to skip this exercise?
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-between', '& .MuiButton-root': { flex: 1 } }}>
                    <Button onClick={handleCancelDialogCancel} color="primary" sx={{ color: '#787878' }}>
                        Keep trying
                    </Button>
                    <Button onClick={handleCancelDialogConfirm} color="primary" sx={{ color: '#787878' }}>
                        Skip
                    </Button>
                </DialogActions>
            </Dialog>
        </Stack >
    );
};

export default DialogPromptSpeakNoGradeComponent;
