import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { DialogPlanLlmPrompt, DialogPlanLlmPromptApi, DialogPlanLlmPromptQuizQuestion } from "../../api/DialogPlanLlmPromptApi";
import ErrorMessageContext, { handleErrorMessage } from "../../context/ErrorMessageContext";

interface DialogPlanLlmPromptQuizQuestionFormProps {
    dialogPlanLlmPromptId?: number;
    dialogPlanLlmPrompt?: DialogPlanLlmPromptQuizQuestion;
    setDialogPlanLlmPrompt?: (dialogPlanLlmPrompt: DialogPlanLlmPrompt) => void;
    handleLlmPromptChange?: (dialogPlanLlmPrompt: DialogPlanLlmPrompt) => void;
    handleRegenerateDialogPlanLlmPrompt?: (dialogPlanLlmPrompt: DialogPlanLlmPrompt, fieldName: string) => void;
    isRegenerating?: boolean;

    id: number;
    dialogPlanId: number;
    orderIndex: number;
    isTeacher: boolean;
    prompt: string;
    isNoteToStudent: boolean;
    yesResponse: string;
    noResponse: string;
    imageSource: string;
}

export const DialogPlanLlmPromptQuizQuestionForm: React.FC<DialogPlanLlmPromptQuizQuestionFormProps> = ({
    dialogPlanLlmPromptId, dialogPlanLlmPrompt, setDialogPlanLlmPrompt, handleLlmPromptChange, handleRegenerateDialogPlanLlmPrompt, isRegenerating,
    id, dialogPlanId, orderIndex, isTeacher, prompt, isNoteToStudent, yesResponse, noResponse, imageSource }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();

    const [teacherChinesePrompt, setTeacherChinesePrompt] = React.useState<string>('');
    const [correctVocabularyWord, setCorrectVocabularyWord] = React.useState<string | null>('');
    const [correctVocabularyWordImage, setCorrectVocabularyWordImage] = React.useState<string | null>('');

    const [incorrectVocabularyWordList, setIncorrectVocabularyWordList] = React.useState<string | null>('');
    const [incorrectVocabularyWordImageList, setIncorrectVocabularyWordImageList] = React.useState<string | null>('');
    const [displayPinyin, setDisplayPinyin] = React.useState<boolean | null>(null);

    const handleTeacherChinesePromptChange = (e: React.ChangeEvent<HTMLInputElement>) => { setTeacherChinesePrompt(e.target.value); };
    const handleCorrectVocabularyWordChange = (e: React.ChangeEvent<HTMLInputElement>) => { setCorrectVocabularyWord(e.target.value); };
    const handleCorrectVocabularyWordImageChange = (e: React.ChangeEvent<HTMLInputElement>) => { setCorrectVocabularyWordImage(e.target.value); };
    const handleIncorrectVocabularyWordList = (e: React.ChangeEvent<HTMLInputElement>) => { setIncorrectVocabularyWordList(e.target.value); };
    const handleIncorrectVocabularyWordImageList = (e: React.ChangeEvent<HTMLInputElement>) => { setIncorrectVocabularyWordImageList(e.target.value); };
    const handleDisplayPinyinChange = (e: React.ChangeEvent<HTMLInputElement>) => { setDisplayPinyin(e.target.checked); };

    React.useEffect(() => {
        if (!dialogPlanLlmPromptId && dialogPlanLlmPrompt) {
            setDialogPlanLlmPromptFields(dialogPlanLlmPrompt);
        }
    }, [])
    React.useEffect(() => {
        if (dialogPlanLlmPromptId && !dialogPlanLlmPrompt) {
            DialogPlanLlmPromptApi.getById(dialogPlanLlmPromptId)
                .then((response) => {
                    const dialogPlanLlmPrompt = response.data as DialogPlanLlmPromptQuizQuestion;
                    setDialogPlanLlmPromptFields(dialogPlanLlmPrompt);
                })
                .catch(err => {
                    handleErrorMessage(err, setErrorMessage, history);
                })
        }
    }, [dialogPlanLlmPromptId])


    function setDialogPlanLlmPromptFields(dialogPlanLlmPrompt: DialogPlanLlmPromptQuizQuestion) {
        if (dialogPlanLlmPrompt.class_name == "DialogPlanLlmPromptQuizQuestionModel") {
            setTeacherChinesePrompt(dialogPlanLlmPrompt.teacher_chinese_prompt);
            setCorrectVocabularyWord(dialogPlanLlmPrompt.correct_vocabulary_word);
            setCorrectVocabularyWordImage(dialogPlanLlmPrompt.correct_vocabulary_word_image);
            setIncorrectVocabularyWordList(dialogPlanLlmPrompt.incorrect_vocabulary_word_list);
            setIncorrectVocabularyWordImageList(dialogPlanLlmPrompt.incorrect_vocabulary_word_image_list);
            setDisplayPinyin(dialogPlanLlmPrompt.display_pinyin);
        }
    }

    React.useEffect(() => {
        const newDialogPlanLlmPrompt = new DialogPlanLlmPromptQuizQuestion(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            yesResponse,
            noResponse,
            imageSource,
            teacherChinesePrompt,
            correctVocabularyWord,
            correctVocabularyWordImage,
            incorrectVocabularyWordList,
            incorrectVocabularyWordImageList,
            displayPinyin
        );
        if (handleLlmPromptChange) {
            handleLlmPromptChange(newDialogPlanLlmPrompt);
        }
        if (setDialogPlanLlmPrompt) {
            setDialogPlanLlmPrompt(newDialogPlanLlmPrompt);
        }
    }, [id, dialogPlanId, orderIndex, isTeacher, prompt, isNoteToStudent, yesResponse, noResponse, imageSource, teacherChinesePrompt,
        correctVocabularyWord, correctVocabularyWordImage, incorrectVocabularyWordList, incorrectVocabularyWordImageList, displayPinyin])

    return (<>
        <Stack spacing={4} sx={{
            width: dialogPlanLlmPrompt ? '100%' : '90%', // Conditionally set width
            margin: '0 auto', pt: 2, pb: 4
        }}>
            <div style={{ width: '100%' }}>
                <TextField
                    label="Chinese Prompt (optional)"
                    value={teacherChinesePrompt}
                    onChange={handleTeacherChinesePromptChange}
                    fullWidth
                    multiline
                    rows={5}
                />
                <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                    Use &lt;Answer/&gt; to mark where the response is inserted
                </Typography>
                {(handleRegenerateDialogPlanLlmPrompt && dialogPlanLlmPrompt) && <>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Button variant="contained"
                            onClick={() => handleRegenerateDialogPlanLlmPrompt(dialogPlanLlmPrompt, "teacher_chinese_prompt")}
                            disabled={isRegenerating}>
                            Regenerate
                            {isRegenerating && <CircularProgress size={16} sx={{ ml: 2 }} />}
                        </Button>
                    </Box>
                </>}
            </div>
            <FormControlLabel
                    control={<Checkbox checked={displayPinyin ? displayPinyin : false} onChange={handleDisplayPinyinChange} />}
                    label="Display answers with Pinyin"
                />
            <h3>Correct Answer</h3>
            <TextField
                label="Prompt for Correct Answer Word"
                value={correctVocabularyWord}
                onChange={handleCorrectVocabularyWordChange}
                multiline
                rows={5}
            />
            {(handleRegenerateDialogPlanLlmPrompt && dialogPlanLlmPrompt) && <>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button variant="contained"
                        onClick={() => handleRegenerateDialogPlanLlmPrompt(dialogPlanLlmPrompt, "correct_vocabulary_word")}
                        disabled={isRegenerating}>
                        Regenerate
                        {isRegenerating && <CircularProgress size={16} sx={{ ml: 2 }} />}
                    </Button>
                </Box>
            </>}
            <TextField
                label="Prompt for Correct Answer Image"
                value={correctVocabularyWordImage}
                onChange={handleCorrectVocabularyWordImageChange}
                multiline
                rows={5}
            />
            {(handleRegenerateDialogPlanLlmPrompt && dialogPlanLlmPrompt) && <>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button variant="contained"
                        onClick={() => handleRegenerateDialogPlanLlmPrompt(dialogPlanLlmPrompt, "correct_vocabulary_word_image")}
                        disabled={isRegenerating}>
                        Regenerate
                        {isRegenerating && <CircularProgress size={16} sx={{ ml: 2 }} />}
                    </Button>
                </Box>
            </>}
            {/* spacer for image on the right */}
            <Box sx={{ height: '183px', marginTop: '0px !important' }} />
            <Stack spacing={4} sx={{ width: '90%', margin: '0 auto', pt: 2, pb: 4 }}>
                <h3>Incorrect Answer</h3>
                <TextField
                    label="Prompt for Incorrect Answer Word List"
                    value={incorrectVocabularyWordList}
                    onChange={handleIncorrectVocabularyWordList}
                    multiline
                    rows={5}
                />
                {(handleRegenerateDialogPlanLlmPrompt && dialogPlanLlmPrompt) && <>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Button variant="contained"
                            onClick={() => handleRegenerateDialogPlanLlmPrompt(dialogPlanLlmPrompt, "incorrect_vocabulary_word_list")}
                            disabled={isRegenerating}>
                            Regenerate
                            {isRegenerating && <CircularProgress size={16} sx={{ ml: 2 }} />}
                        </Button>
                    </Box>
                </>}
                <TextField
                    label="Prompt for Incorrect Answer Image List"
                    value={incorrectVocabularyWordImageList}
                    onChange={handleIncorrectVocabularyWordImageList}
                    multiline
                    rows={5}
                />
                <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                    &lt;IncorrectVocabularyWord/&gt; will be replaced with
                    the corresponding incorrect vocabulary word when generating.
                </Typography>
                {(handleRegenerateDialogPlanLlmPrompt && dialogPlanLlmPrompt) && <>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Button variant="contained"
                            onClick={() => handleRegenerateDialogPlanLlmPrompt(dialogPlanLlmPrompt, "incorrect_vocabulary_word_image_list")}
                            disabled={isRegenerating}>
                            Regenerate
                            {isRegenerating && <CircularProgress size={16} sx={{ ml: 2 }} />}
                        </Button>
                    </Box>
                </>}
            </Stack>

        </Stack>
    </>);
};
