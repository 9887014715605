import { Box, Button, Stack, TextField } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { DialogPlanLlmPrompt } from '../../api/DialogPlanLlmPromptApi';
import { DialogPromptConversationListen } from '../../api/DialogPromptApi';
import { IDialogPromptConversation } from '../../api/DialogPromptConversationApi';
import ErrorMessageContext from '../../context/ErrorMessageContext';

interface DialogPlanLlmPromptConversationListenGeneratedProps {
    dialogPrompt?: DialogPromptConversationListen; // if llmPrompt was generated
    dialogPromptConversation?: IDialogPromptConversation | null; // if llmPrompt was generated
    onSubmit?: (updatedDialogPlanLlmPrompt: DialogPlanLlmPrompt, setIsSaving: React.Dispatch<React.SetStateAction<boolean>>) => void;
    onDelete?: (dialogPlanLlmPromptId: number) => void;

    isRegenerating?: boolean;
    handleLlmPromptChange?: (dialogPlanLlmPrompt: DialogPlanLlmPrompt) => void;
    handleRegenerateDialogPlanLlmPrompt?: (dialogPlanLlmPrompt: DialogPlanLlmPrompt, fieldName: string) => void;
}

const DialogPlanLlmPromptConversationListenGenerated: React.FC<DialogPlanLlmPromptConversationListenGeneratedProps> = ({ dialogPrompt, dialogPromptConversation, onSubmit, onDelete, isRegenerating, handleLlmPromptChange, handleRegenerateDialogPlanLlmPrompt }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();

    const [dialogPromptConversationText, setDialogPromptConversationText] = React.useState("");

    React.useEffect(() => {
        if (dialogPrompt) {
            setDialogPromptFields(dialogPrompt);
        }
    }, [dialogPrompt])

    function setDialogPromptFields(dialogPrompt: DialogPromptConversationListen) {
        // setDialogPromptConversationText(dialogPrompt.dialog_prompt_conversation ? dialogPrompt.dialog_prompt_conversation : "")
    }
    React.useEffect(() => {
        if (dialogPromptConversation) {
            // setDialogPromptConversationText(dialogPromptConversation.dialog_prompt_sentence_list)
            setDialogPromptConversationText(dialogPromptConversation.dialog_prompt_sentence_list
                ? dialogPromptConversation.dialog_prompt_sentence_list
                    .map(item => item.sentence_text)
                    .join("\n") // Convert array to newline-separated string
                : ""
            )
        }
    }, [dialogPromptConversation])

    // update dialog prompt
    const handleDialogPromptConversationChange = (e: React.ChangeEvent<HTMLInputElement>) => { 
        const conversationText = e.target.value;
        setDialogPromptConversationText(conversationText); 
        if (dialogPromptConversation) {
            const lines = conversationText.split("\n").filter(line => line.trim() !== "");

            // Map each line to an IDialogPromptSentence object
            dialogPromptConversation.dialog_prompt_sentence_list = lines.map((studentResponse, index) => ({
                id: 0, // Assuming `id` is generated by the backend
                sentence_id: 0, // Assuming this is set later or generated by the backend
                sentence_text: studentResponse.trim(), // Assign the trimmed response
                order_index: index + 1, // Incremental order based on line position
            }));
        } 
    };

    return (<>
        <Stack spacing={4} sx={{ width: '100%', margin: '0 auto', pt: 2, pb: 4 }}>

            <TextField
                label="Conversation Dialog"
                value={dialogPromptConversationText}
                onChange={handleDialogPromptConversationChange}
                multiline
                rows={5}
            />
            {/* Hidden spacer button */}
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Button variant="contained"
                    disabled={true}
                    sx={{ opacity: 0 }}
                >
                    Regenerate
                </Button>
            </Box>
        </Stack>
    </>);
};

export default DialogPlanLlmPromptConversationListenGenerated;