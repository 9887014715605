import '../style/global.css';

import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { useHistory } from 'react-router-dom';
import { UserApi } from "../api/UserApi";
import background from '../asset/background-signin.png';
import ErrorMessageContext, { handleErrorMessage } from "../context/ErrorMessageContext";

export default function TopReferrer() {
    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();

    const [referrerList, setReferrerList] = React.useState<[]>([]);
    const [referrerLink, setReferrerLink] = React.useState<string>('broken');

    React.useEffect(() => {
        UserApi.getReferrerLink()
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error("Unable to UserApi.getReferrerLink() " + response.status)
            })
            .then(link => setReferrerLink(link))
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history);
            })
        UserApi.getReferrerList()
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error("Unable to UserApi.getReferrerList() " + response.status)
            })
            .then(list => setReferrerList(list))
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history);
            })
    }, []);

    return (
        <div style={{
            display: 'flex', // Use flex to allow easy centering
            flexDirection: 'column', // Changed to stack children vertically
            justifyContent: 'center', // Center horizontally
            alignItems: 'center', // Center vertically
            minHeight: 'calc(100vh - 85px)', // Ensure the div takes at least the full viewport height
            width: '100vw', // Ensure the div takes the full viewport width
            padding: 0, // Reset any padding
            backgroundImage: `url(${background})`, // Your background image
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover', // Cover the entire size of the div
            backgroundPosition: 'center', // Center the background image
        }}>
            <Typography variant="h4">We need your help getting the word out!</Typography>
            <Typography variant="h5" sx={{mt: 2}}>If you find this site useful, <span style={{ color: '#E6B800 ', fontWeight: 'bold' }}>share your audio recordings</span> with your friends on social media.
            </Typography>
            <Typography variant="h5" sx={{mt: 2}}>
            You can also refer your friends to this site using this link:
            </Typography>
            <Typography variant="h5" sx={{ color: '#0062FF' }}>{referrerLink}</Typography>
            <Typography variant="h5" sx={{mt: 2}}>
                If this site takes off, the top 10 referrers will recieve a free 5 year, 10 year or maybe lifetime subscription.
            </Typography>
            <Typography variant="h5">
                Additional rewards beyond the top 10 are likely.
            </Typography>
            <Box
                sx={{
                    mt:2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxHeight: 'calc(100vh - 300px)', // Viewport height minus 100px
                    width: '60%', // Set the width of the Box
                    backgroundColor: '#FFFFFF',
                    borderRadius: '16px',
                    overflowY: 'auto', // Allows content to scroll if it overflows the Y axis
                    border: '1.5px solid rgba(82, 99, 125, 0.2)', // Added border with color and transparency
                }}
            >
                <TableContainer sx={{ overflowX: 'auto', p: 2, my: 4 }}>
                    <Table aria-label="simple table" sx={{ minWidth: 650, border: 1, borderColor: 'rgba(224, 224, 224, 1)', margin: 'auto', width: 'auto' }}>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row" sx={{ py: 1, borderTopLeftRadius: '16px' }} align="left">
                                    <Typography variant="h4">First Name</Typography>
                                </TableCell>
                                <TableCell component="th" scope="row" sx={{ py: 1, borderTopLeftRadius: '16px' }}align="center">
                                    <Typography variant="h4">Referral Count</Typography>
                                </TableCell>
                            </TableRow>
                            {referrerList.map((referrer, index) => (
                                <TableRow>
                                    <TableCell align="left" sx={{
                                        py: 1,
                                    }}>
                                        {referrer['first_name']}
                                    </TableCell>
                                    <TableCell align="center" sx={{
                                        py: 1,
                                    }}>
                                        {referrer['ref_count']}
                                    </TableCell>
                                </TableRow>
                            ))}

                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    );
}