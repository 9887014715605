import { Button, Stack } from '@mui/material';
import React from 'react';
import { DialogPromptSelectTeacher } from '../../api/DialogPromptApi';
import ChatBubble from '../ChatBubble';

import { useHistory } from 'react-router-dom';
import { AudioApi } from '../../api/AudioApi';
import { IUser, UserApi } from '../../api/UserApi';
import female_avatar_1 from '../../asset/female_avatar_1.jpg';
import female_avatar_2 from '../../asset/female_avatar_2.jpg';
import male_avatar from '../../asset/male_avatar.jpg';
import ErrorMessageContext, { handleErrorMessage } from '../../context/ErrorMessageContext';
import { TeacherIntroductionFemale, TeacherIntroductionFemale2, TeacherIntroductionMale, getAudioByText } from './DialogPromptComponent';

interface DialogPromptSelectTeacherComponentProps {
    dialogPrompt: DialogPromptSelectTeacher;
    setDialogPromptComplete: (dialogPromptId: number) => void;
    setPromptInputComponent: (component: React.ReactNode | null) => void;
    showAvatar?: boolean;
    chatBubbleContainerRef: React.RefObject<HTMLDivElement>;
    isSettings?: boolean;
}

const DialogPromptSelectTeacherComponent: React.FC<DialogPromptSelectTeacherComponentProps> = ({ dialogPrompt, setDialogPromptComplete, setPromptInputComponent, showAvatar = true, chatBubbleContainerRef, isSettings=false }) => {

    const [response, setResponse] = React.useState<string | null>(null);
    const [responseAudio, setResponseAudio] = React.useState<Blob | null>(null);

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)

    const [chatBubbleDone, setChatBubbleDone] = React.useState(false);

    const history = useHistory();

    
    function playTeacherIntroductionAudio(teacher: string) {
        let audioPromise;

        if (teacher == "male") {
            audioPromise = AudioApi.getMaleByText(TeacherIntroductionMale)
        } else if (teacher == 'female') {
            audioPromise = AudioApi.getFemaleByText(TeacherIntroductionFemale)
        } else if (teacher == 'female2') {
            audioPromise = AudioApi.getFemale2ByText(TeacherIntroductionFemale2)
        } else {
            throw Error("unknown selected teacher name in local storage " + teacher);
        }

        audioPromise
            .then(response => {
                // check if the response was successful
                if (response.status >= 300) {
                    throw new Error('Getting audio was not ok statuscode=' + response.status + " " + response.statusText);
                }
                return response.data;
            })
            .then(blob => {
                // create a new Audio object and set the source to the blob URL
                const audio = new Audio(URL.createObjectURL(blob));
                audio.play();
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history);
            });
    };

    const handleMale = async () => {
        localStorage.setItem('teacher', 'male');
        setResponse(TeacherIntroductionMale)
        if (maleAudio != undefined) {
            setResponseAudio(maleAudio)
        }
        playTeacherIntroductionAudio('male')
    }

    const handleFemale = async () => {
        localStorage.setItem('teacher', 'female');
        setResponse(TeacherIntroductionFemale)
        if (femaleAudio != undefined) {
            setResponseAudio(femaleAudio)
        }
        playTeacherIntroductionAudio('female')
    }
    const handleFemale2 = async () => {
        localStorage.setItem('teacher', 'female2');
        setResponse(TeacherIntroductionFemale2)
        if (female2Audio != undefined) {
            setResponseAudio(female2Audio)
        }
        playTeacherIntroductionAudio('female2')
    }

    const handleConfirm = () => {
        setDialogPromptComplete(dialogPrompt.id);
        const selectedTeacher = localStorage.getItem('teacher');
        if (selectedTeacher) {
            updateDbTeacher(selectedTeacher)
        }
    };

    const updateDbTeacher = (teacher: string) => {
        const updatedUser: IUser = {
            chinese_teacher: teacher,
        };
        // Call the update function
        UserApi.update(updatedUser)
            .then(response => {
                // Handle the response
                // console.log('User updated:', response.data);
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history);
            });
    }

    const [promptAudio, setPromptAudio] = React.useState<Blob | null | undefined>(undefined);
    const [maleAudio, setMaleAudio] = React.useState<Blob | null | undefined>(undefined);
    const [femaleAudio, setFemaleAudio] = React.useState<Blob | null | undefined>(undefined);
    const [female2Audio, setFemale2Audio] = React.useState<Blob | null | undefined>(undefined);

    React.useEffect(() => {
        // clear promptInputComponent initially
        setPromptInputComponent(null);
        getAudioByText(history, setErrorMessage, dialogPrompt.prompt, setPromptAudio, isSettings)
        getAudioByText(history, setErrorMessage, TeacherIntroductionMale, setMaleAudio, isSettings, "male")
        getAudioByText(history, setErrorMessage, TeacherIntroductionFemale, setFemaleAudio, isSettings, "female")
        getAudioByText(history, setErrorMessage, TeacherIntroductionFemale2, setFemale2Audio, isSettings, "female2")
    }, []);

    React.useEffect(() => {
        if (chatBubbleDone) {
            setPromptInputComponent(
                <Stack direction="row" alignItems="center" sx={{ justifyContent: 'center' }}>
                    <img src={male_avatar} alt="Male" onClick={() => handleMale()} style={{ maxHeight: '4em', maxWidth: '4em', marginLeft: '1em' }} />
                    <img src={female_avatar_1} alt="Female 1" onClick={() => handleFemale()} style={{ maxHeight: '4em', maxWidth: '4em', marginLeft: '1em' }} />
                    <img src={female_avatar_2} alt="Female 2" onClick={() => handleFemale2()} style={{ maxHeight: '4em', maxWidth: '4em', marginLeft: '1em' }} />
                    <Button variant="contained" onClick={handleConfirm} style={{ marginLeft: '1em' }}>
                        Confirm
                    </Button>
                </Stack>
            )
        }
    }, [chatBubbleDone]);

    return (
        <Stack>
            <ChatBubble
                isTeacher={dialogPrompt.is_teacher}
                message={dialogPrompt.prompt}
                messageAudio={promptAudio ? promptAudio : null}
                showAvatar={showAvatar}
                setChatBubbleDone={setChatBubbleDone}
                chatBubbleContainerRef={chatBubbleContainerRef}
            />
            {/** this is not changing as the teacher selection is changing */}
            {/* {response == null ? null : (
                <ChatBubble
                    isTeacher={dialogPrompt.is_teacher}
                    message={response}
                    messageAudio={responseAudio ? responseAudio : null}
                    showAvatar={showAvatar}
                    chatBubbleContainerRef={chatBubbleContainerRef}
                />
            )} */}
        </Stack>
    );
};

export default DialogPromptSelectTeacherComponent;
