import { Box, Button, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { DialogPlanLlmPrompt, DialogPlanLlmPromptApi, DialogPlanLlmPromptTypeSpeak } from "../../api/DialogPlanLlmPromptApi";
import ErrorMessageContext, { handleErrorMessage } from "../../context/ErrorMessageContext";

interface DialogPlanLlmPromptTypeSpeakFormProps {
    dialogPlanLlmPromptId?: number;
    dialogPlanLlmPrompt?: DialogPlanLlmPromptTypeSpeak;
    setDialogPlanLlmPrompt?:  (dialogPlanLlmPrompt: DialogPlanLlmPrompt) => void;
    handleLlmPromptChange?: (dialogPlanLlmPrompt: DialogPlanLlmPrompt) => void;
    handleRegenerateDialogPlanLlmPrompt?: (dialogPlanLlmPrompt: DialogPlanLlmPrompt, fieldName: string) => void;
    isRegenerating?: boolean;

    id: number;
    dialogPlanId: number;
    orderIndex: number;
    isTeacher: boolean;
    prompt: string;
    isNoteToStudent: boolean;
    yesResponse: string | null;
    imageSource: string | null;
}

export const DialogPlanLlmPromptTypeSpeakForm: React.FC<DialogPlanLlmPromptTypeSpeakFormProps> = ({
    dialogPlanLlmPromptId, dialogPlanLlmPrompt, setDialogPlanLlmPrompt, handleLlmPromptChange, handleRegenerateDialogPlanLlmPrompt, isRegenerating,
    id, dialogPlanId, orderIndex, isTeacher, prompt, isNoteToStudent, yesResponse, imageSource }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();

    const [teacherChinesePrompt, setTeacherChinesePrompt] = React.useState("");
    const [studentResponsePrompt, setStudentResponsePrompt] = React.useState("<Answer/>");
    const [dialogPromptSentenceList, setDialogPromptSentenceList] = React.useState("");
    const [teacherAskStudentToSpeakPrompt, setTeacherAskStudentToSpeakPrompt] = React.useState("");
    const [spokenYesResponse, setSpokenYesResponse] = React.useState("");
    const [spokenNoResponse, setSpokenNoResponse] = React.useState("");
    const handleTeacherChinesePromptChange = (e: React.ChangeEvent<HTMLInputElement>) => { setTeacherChinesePrompt(e.target.value); };
    const handleStudentResponsePromptChange = (e: React.ChangeEvent<HTMLInputElement>) => { setStudentResponsePrompt(e.target.value); };
    const handleDialogPromptSentenceListChange = (e: React.ChangeEvent<HTMLInputElement>) => { setDialogPromptSentenceList(e.target.value); };
    const handleTeacherAskStudentToSpeakPromptChange = (e: React.ChangeEvent<HTMLInputElement>) => { setTeacherAskStudentToSpeakPrompt(e.target.value); };
    const handleSpokenYesResponseChange = (e: React.ChangeEvent<HTMLInputElement>) => { setSpokenYesResponse(e.target.value); };
    const handleSpokenNoResponseChange = (e: React.ChangeEvent<HTMLInputElement>) => { setSpokenNoResponse(e.target.value); };

    React.useEffect(() => {
        if (!dialogPlanLlmPromptId && dialogPlanLlmPrompt) {
            setDialogPlanLlmPromptFields(dialogPlanLlmPrompt);
        }
    }, [])
    React.useEffect(() => {
        if (dialogPlanLlmPromptId && !dialogPlanLlmPrompt) {
            DialogPlanLlmPromptApi.getById(dialogPlanLlmPromptId)
                .then((response) => {
                    const dialogPlanLlmPrompt = response.data as DialogPlanLlmPromptTypeSpeak;
                    setDialogPlanLlmPromptFields(dialogPlanLlmPrompt);
                })
                .catch(err => {
                    handleErrorMessage(err, setErrorMessage, history);
                })
        }
    }, [dialogPlanLlmPromptId])

    function setDialogPlanLlmPromptFields(dialogPlanLlmPrompt: DialogPlanLlmPromptTypeSpeak) {
        if (dialogPlanLlmPrompt.class_name == "DialogPlanLlmPromptTypeSpeakModel") {
            setTeacherChinesePrompt(dialogPlanLlmPrompt.teacher_chinese_prompt ? dialogPlanLlmPrompt.teacher_chinese_prompt : "")
            setStudentResponsePrompt(dialogPlanLlmPrompt.student_response_prompt ? dialogPlanLlmPrompt.student_response_prompt : "")
            setDialogPromptSentenceList(dialogPlanLlmPrompt.dialog_prompt_sentence_list ? dialogPlanLlmPrompt.dialog_prompt_sentence_list : "")
            setTeacherAskStudentToSpeakPrompt(dialogPlanLlmPrompt.teacher_ask_student_to_speak_prompt ? dialogPlanLlmPrompt.teacher_ask_student_to_speak_prompt : "")
            setSpokenYesResponse(dialogPlanLlmPrompt.spoken_yes_response ? dialogPlanLlmPrompt.spoken_yes_response : "")
            setSpokenNoResponse(dialogPlanLlmPrompt.spoken_no_response ? dialogPlanLlmPrompt.spoken_no_response : "")
        }
    }

    React.useEffect(() => {
        const newDialogPlanLlmPrompt = new DialogPlanLlmPromptTypeSpeak(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            yesResponse as string | "ERROR",
            imageSource,
            teacherChinesePrompt,
            studentResponsePrompt,
            dialogPromptSentenceList,
            teacherAskStudentToSpeakPrompt,
            spokenYesResponse,
            spokenNoResponse
        );
        if (handleLlmPromptChange) {
            handleLlmPromptChange(newDialogPlanLlmPrompt);
        }
        if (setDialogPlanLlmPrompt) {
            setDialogPlanLlmPrompt(newDialogPlanLlmPrompt);
        }
    }, [id, dialogPlanId, orderIndex, isTeacher, prompt, isNoteToStudent, yesResponse, imageSource,
        teacherChinesePrompt, studentResponsePrompt, dialogPromptSentenceList, teacherAskStudentToSpeakPrompt, spokenYesResponse, spokenNoResponse])

    return (
        <Stack spacing={4} sx={{
            width: dialogPlanLlmPrompt ? '100%' : '90%', // Conditionally set width
            margin: '0 auto', pt: 2, pb: 4
        }}>

            <TextField
                label="LLM Prompt for Teacher Chinese Prompt"
                value={teacherChinesePrompt}
                onChange={handleTeacherChinesePromptChange}
                multiline
                rows={5}
            />
            {(handleRegenerateDialogPlanLlmPrompt && dialogPlanLlmPrompt) && <>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button variant="contained"
                        onClick={() => handleRegenerateDialogPlanLlmPrompt(dialogPlanLlmPrompt, "teacher_chinese_prompt")}
                        disabled={isRegenerating}>
                        Regenerate
                        {isRegenerating && <CircularProgress size={16} sx={{ ml: 2 }} />}
                    </Button>
                </Box>
            </>}
            <div>
                <TextField
                    label="LLM Prompt for Student Chinese Prompt"
                    value={studentResponsePrompt ? studentResponsePrompt : "<Answer/>"}
                    onChange={handleStudentResponsePromptChange}
                    required
                    sx={{ width: '100%' }}
                    multiline
                    rows={5}
                />
                <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                    Use &lt;Answer/&gt; to mark where the response is inserted
                </Typography>
                {(handleRegenerateDialogPlanLlmPrompt && dialogPlanLlmPrompt) && <>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Button variant="contained"
                            onClick={() => handleRegenerateDialogPlanLlmPrompt(dialogPlanLlmPrompt, "student_response_prompt")}
                            disabled={isRegenerating}>
                            Regenerate
                            {isRegenerating && <CircularProgress size={16} sx={{ ml: 2 }} />}
                        </Button>
                    </Box>
                </>}
            </div>
            <TextField
                label="LLM Prompt for Allowed Student Prompt Responses"
                value={dialogPromptSentenceList}
                onChange={handleDialogPromptSentenceListChange}
                multiline
                rows={5}
                sx={{ width: '100%' }}
                required
            />
            {(handleRegenerateDialogPlanLlmPrompt && dialogPlanLlmPrompt) && <>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button variant="contained"
                        onClick={() => handleRegenerateDialogPlanLlmPrompt(dialogPlanLlmPrompt, "dialog_prompt_sentence_list")}
                        disabled={isRegenerating}>
                        Regenerate
                        {isRegenerating && <CircularProgress size={16} sx={{ ml: 2 }} />}
                    </Button>
                </Box>
            </>}
            <TextField
                label="LLM Prompt for Teacher Ask Student To Speak Prompt"
                value={teacherAskStudentToSpeakPrompt}
                onChange={handleTeacherAskStudentToSpeakPromptChange}
                required
                multiline
                rows={5}
            />
            {(handleRegenerateDialogPlanLlmPrompt && dialogPlanLlmPrompt) && <>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button variant="contained"
                        onClick={() => handleRegenerateDialogPlanLlmPrompt(dialogPlanLlmPrompt, "teacher_ask_student_to_speak_prompt")}
                        disabled={isRegenerating}>
                        Regenerate
                        {isRegenerating && <CircularProgress size={16} sx={{ ml: 2 }} />}
                    </Button>
                </Box>
            </>}
            <TextField
                label="LLM Prompt for Student well spoken word"
                value={spokenYesResponse}
                onChange={handleSpokenYesResponseChange}
                required
                multiline
                rows={5}
            />
            {(handleRegenerateDialogPlanLlmPrompt && dialogPlanLlmPrompt) && <>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button variant="contained"
                        onClick={() => handleRegenerateDialogPlanLlmPrompt(dialogPlanLlmPrompt, "spoken_yes_response")}
                        disabled={isRegenerating}>
                        Regenerate
                        {isRegenerating && <CircularProgress size={16} sx={{ ml: 2 }} />}
                    </Button>
                </Box>
            </>}
            <TextField
                label="LLM Prompt for Student poorly spoken word"
                value={spokenNoResponse}
                onChange={handleSpokenNoResponseChange}
                required
                multiline
                rows={5}
            />
            {(handleRegenerateDialogPlanLlmPrompt && dialogPlanLlmPrompt) && <>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button variant="contained"
                        onClick={() => handleRegenerateDialogPlanLlmPrompt(dialogPlanLlmPrompt, "spoken_no_response")}
                        disabled={isRegenerating}>
                        Regenerate
                        {isRegenerating && <CircularProgress size={16} sx={{ ml: 2 }} />}
                    </Button>
                </Box>
            </>}
        </Stack>
    )
}