import { Box, Button, CircularProgress, Stack, TextField } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { DialogPlanLlmPrompt, DialogPlanLlmPromptApi, DialogPlanLlmPromptQuizWord } from "../../api/DialogPlanLlmPromptApi";
import ErrorMessageContext, { handleErrorMessage } from "../../context/ErrorMessageContext";

interface DialogPlanLlmPromptQuizWordFormProps {
    dialogPlanLlmPromptId?: number;
    dialogPlanLlmPrompt?: DialogPlanLlmPromptQuizWord;
    setDialogPlanLlmPrompt?:  (dialogPlanLlmPrompt: DialogPlanLlmPrompt) => void;
    handleLlmPromptChange?: (dialogPlanLlmPrompt: DialogPlanLlmPrompt) => void;
    handleRegenerateDialogPlanLlmPrompt?: (dialogPlanLlmPrompt: DialogPlanLlmPrompt, fieldName: string) => void;
    isRegenerating?: boolean;

    id: number;
    dialogPlanId: number;
    orderIndex: number;
    isTeacher: boolean;
    prompt: string;
    isNoteToStudent: boolean;
    newWord: string | null;
    yesResponse: string | null;
    noResponse: string | null;
}


export const DialogPlanLlmPromptQuizWordForm: React.FC<DialogPlanLlmPromptQuizWordFormProps> = ({
    dialogPlanLlmPromptId, dialogPlanLlmPrompt, setDialogPlanLlmPrompt, handleLlmPromptChange, handleRegenerateDialogPlanLlmPrompt, isRegenerating,
    id, dialogPlanId, orderIndex, isTeacher, prompt, isNoteToStudent, newWord, yesResponse, noResponse }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();

    const [wrongWordList, setWrongWordList] = React.useState("");

    const handleWrongWordListChange = (e: React.ChangeEvent<HTMLInputElement>) => { setWrongWordList(e.target.value); };

    React.useEffect(() => {
        if (!dialogPlanLlmPromptId && dialogPlanLlmPrompt) {
            setDialogPlanLlmPromptFields(dialogPlanLlmPrompt);
        }
    }, [])
    React.useEffect(() => {
        if (dialogPlanLlmPromptId && !dialogPlanLlmPrompt) {
            DialogPlanLlmPromptApi.getById(dialogPlanLlmPromptId)
                .then((response) => {
                    const dialogPlanLlmPrompt = response.data as DialogPlanLlmPromptQuizWord;
                    setDialogPlanLlmPromptFields(dialogPlanLlmPrompt);
                })
                .catch(err => {
                    handleErrorMessage(err, setErrorMessage, history);
                })
        }
    }, [dialogPlanLlmPromptId])

    function setDialogPlanLlmPromptFields(dialogPlanLlmPrompt: DialogPlanLlmPromptQuizWord) {
        if (dialogPlanLlmPrompt.class_name == "DialogPlanLlmPromptQuizWordModel") {
            setWrongWordList(dialogPlanLlmPrompt.wrong_word_list ? dialogPlanLlmPrompt.wrong_word_list : "")
        }
    }

    React.useEffect(() => {
        const newDialogPlanLlmPrompt = new DialogPlanLlmPromptQuizWord(
            id,
            dialogPlanId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            newWord as string | "ERROR",
            yesResponse as string | "ERROR",
            noResponse as string | "ERROR",
            wrongWordList as string | "ERROR",
        );
        if (handleLlmPromptChange) {
            handleLlmPromptChange(newDialogPlanLlmPrompt);
        }
        if (setDialogPlanLlmPrompt) {
            setDialogPlanLlmPrompt(newDialogPlanLlmPrompt);
        }
    }, [id, dialogPlanId, orderIndex, isTeacher, prompt, isNoteToStudent,
        newWord, yesResponse, noResponse,
        wrongWordList])

    return (
        <Stack spacing={4} sx={{
            width: dialogPlanLlmPrompt ? '100%' : '90%', // Conditionally set width
            margin: '0 auto', pt: 2, pb: 4
        }}>

            <TextField
                label="Prompt for Wrong Word List"
                value={wrongWordList}
                onChange={handleWrongWordListChange}
                required
                multiline
                rows={5}
            />
            {(handleRegenerateDialogPlanLlmPrompt && dialogPlanLlmPrompt) && <>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button variant="contained"
                        onClick={() => handleRegenerateDialogPlanLlmPrompt(dialogPlanLlmPrompt, "wrong_word_list")}
                        disabled={isRegenerating}>
                        Regenerate
                        {isRegenerating && <CircularProgress size={16} sx={{ ml: 2 }} />}
                    </Button>
                </Box>
            </>}
        </Stack>
    )
}