import { Box, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { AudioApi } from '../../api/AudioApi';
import { DialogPromptConversationListen } from '../../api/DialogPromptApi';
import { DialogPromptConversationApi, IDialogPromptConversation } from '../../api/DialogPromptConversationApi';
import SpeakerBlueIcon from '../../asset/icon-speaker-blue.svg';
import ErrorMessageContext, { handleErrorMessage } from '../../context/ErrorMessageContext';
import { useKaiTiFontStyles } from '../../font/KaiTiFont';
import ChatBubble from '../ChatBubble';
import { getAudioByText } from './DialogPromptComponent';


interface DialogPromptConversationListenComponentProps {
    dialogPrompt: DialogPromptConversationListen;
    setDialogPromptComplete: (dialogPromptId: number) => void;
    setPromptInputComponent: (component: React.ReactNode | null) => void;
    showAvatar?: boolean;
    chatBubbleContainerRef: React.RefObject<HTMLDivElement>;
    isSettings?: boolean;
}

const DialogPromptConversationListenComponent: React.FC<DialogPromptConversationListenComponentProps> = ({ dialogPrompt, setDialogPromptComplete, setPromptInputComponent, showAvatar = true, chatBubbleContainerRef, isSettings = false }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();

    const kaiTiFontClass = useKaiTiFontStyles();
    const storedLearnWithCharacter = localStorage.getItem('learnWithCharacter');
    const learnWithCharacter = storedLearnWithCharacter ? JSON.parse(storedLearnWithCharacter) : false;

    const [chatBubbleDone, setChatBubbleDone] = React.useState(false);
    const [dialogPromptIndex, setDialogPromptIndex] = React.useState(0);
    const [conversation, setConversation] = React.useState<IDialogPromptConversation | null>(null);
    const dialogPromptIndexRef = React.useRef(0); // Initialize the ref outside the component function

    const [promptAudio, setPromptAudio] = React.useState<Blob | null | undefined>(undefined);

    React.useEffect(() => {
        setPromptInputComponent(null)
        getAudioByText(history, setErrorMessage, dialogPrompt.prompt, setPromptAudio)
        DialogPromptConversationApi.getById(dialogPrompt.dialog_prompt_conversation_id)
            .then(response => {
                setConversation(response.data)
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history);
            })
    }, []);

    const handleSpeakerClick = (dialogPromptIndex: (number | null) = null) => {
        let audioPromise;
        const advanceDialogPromptIndexRef = dialogPromptIndex == null || dialogPromptIndex == dialogPromptIndexRef.current;
        if (dialogPromptIndex == null) {
            dialogPromptIndex = dialogPromptIndexRef.current
        }

        if (conversation) {
            if (dialogPromptIndex % 2 === 0) {
                audioPromise = AudioApi.getSpeakerA(conversation.dialog_prompt_sentence_list[dialogPromptIndex].sentence_id)
            } else {
                audioPromise = AudioApi.getSpeakerB(conversation.dialog_prompt_sentence_list[dialogPromptIndex].sentence_id)
            }

            audioPromise
                .then(response => {
                    // check if the response was successful
                    if (response.status >= 300) {
                        throw new Error('Getting male audio was not ok statuscode=' + response.status + " " + response.statusText);
                    }
                    return response.data;
                })
                .then(blob => {
                    // create a new Audio object and set the source to the blob URL
                    const audio = new Audio(URL.createObjectURL(blob));
                    audio.addEventListener('ended', () => {
                        if (advanceDialogPromptIndexRef) {
                            dialogPromptIndexRef.current += 1;
                            if (dialogPromptIndexRef.current >= conversation.dialog_prompt_sentence_list.length) {
                                setDialogPromptComplete(dialogPrompt.id)
                            } else {
                                setDialogPromptIndex(dialogPromptIndexRef.current);
                            }
                        }
                    }); // Listen to the 'ended' event
                    audio.play();
                })
                .catch(err => {
                    handleErrorMessage(err, setErrorMessage, history);
                })
        }
    };

    React.useEffect(() => {
        if (chatBubbleDone) {
            if (chatBubbleContainerRef.current) {
                chatBubbleContainerRef.current.scrollIntoView({ behavior: 'smooth' });
            }
            setPromptInputComponent(
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <IconButton onClick={(event) => handleSpeakerClick()}>
                        <img src={SpeakerBlueIcon} style={{ width: '36px', height: '36px' }} />
                    </IconButton>
                </div>
            )
        }
    }, [chatBubbleDone]);

    React.useEffect(() => {
        if (chatBubbleContainerRef.current) {
            chatBubbleContainerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    })


    return (
        <Stack>
            <ChatBubble
                isTeacher={dialogPrompt.is_teacher}
                message={dialogPrompt.prompt}
                messageAudio={promptAudio ? promptAudio : null}
                showAvatar={showAvatar}
                setChatBubbleDone={setChatBubbleDone}
                chatBubbleContainerRef={chatBubbleContainerRef}
            />
            {chatBubbleDone && conversation && (
                conversation.dialog_prompt_sentence_list.slice(0, dialogPromptIndex + 1).map((sentence, sentenceIndex) => (
                    <Box display="inline-block">
                        <Box display="inline-flex" width="70px" /> {/* This acts as a spacer */}
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent='flex-start'
                            key={sentenceIndex}
                            style={{
                                margin: '1em',
                                display: 'inline-flex',
                                backgroundColor: sentenceIndex % 2 === 0 ? '#FFFFFF' : '#F2F2F2', // Example color condition
                            }} // Add margin to create space between Stacks
                            className='border-dark'
                        >
                            <Box sx={{ borderRight: '1.5px solid rgba(82, 99, 125, 0.8)', pr: '16px', display: 'flex', alignItems: 'center', mr: '8px' }}>
                                {sentenceIndex % 2 === 0 && (<>
                                    <Typography sx={{
                                        fontSize: '36px',
                                        pl: '16px',
                                    }}>A</Typography>
                                </>)}
                                {sentenceIndex % 2 !== 0 && (<>
                                    <Typography sx={{
                                        fontSize: '36px',
                                        pl: '16px',
                                    }}>B</Typography>
                                </>)}

                            </Box>
                            <IconButton onClick={(event) => handleSpeakerClick(sentenceIndex)}>
                                <img src={SpeakerBlueIcon} style={{ width: '24px', height: '24px' }} />
                            </IconButton>
                            {dialogPrompt.class_name != "DialogPromptConversationListenWithoutCharacterModel" &&
                                <div className={kaiTiFontClass.customFont} style={{ fontSize: '36px', paddingLeft: '16px', margin: 0, padding: 0, lineHeight: 0.9 }} >
                                    <span>{sentence.sentence_text}</span>
                                </div>
                            }
                        </Stack>
                    </Box>
                ))
            )}
        </Stack >
    );
};

export default DialogPromptConversationListenComponent;
