import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Icon, Modal, Stack, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { AxiosResponse } from 'axios';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { CourseApi } from '../api/CourseApi';
import { IDialog } from '../api/DialogApi';
import { DialogPrompt, DialogPromptApi, DialogPromptConversationListen, DialogPromptConversationListenSpeak, DialogPromptConversationListenWithoutCharacter, DialogPromptConversationReadSpeak, DialogPromptConversationReadWithBlankSpeak, DialogPromptConversationSpeakOnly, DialogPromptFactory, DialogPromptImage, DialogPromptIntroduceWord, DialogPromptLearnWithCharacter, DialogPromptListenSpeak, DialogPromptListenSpeakNoGrade, DialogPromptMatchWordPicture, DialogPromptQuizQuestion, DialogPromptQuizQuestionAudio, DialogPromptQuizWord, DialogPromptSelectNextDialog, DialogPromptSelectTeacher, DialogPromptSpeakNoGrade, DialogPromptSpeakWithGrade, DialogPromptStartClass, DialogPromptStartNextLesson, DialogPromptTypeSpeak } from '../api/DialogPromptApi';
import { LessonApi } from '../api/LessonApi';
import LeftArrowBlueIcon from '../asset/icon-left-arrow-blue.png';
import NoteBlueIcon from '../asset/icon-note-blue.png';
import ErrorMessageContext, { handleErrorMessage } from '../context/ErrorMessageContext';
import DialogPromptComponent from './dialogPrompt/DialogPromptComponent';
import DialogPromptConversationListenComponent from './dialogPrompt/DialogPromptConversationListenComponent';
import DialogPromptConversationRelatedComponent from './dialogPrompt/DialogPromptConversationRelatedComponent';
import DialogPromptEnterNameComponent from './dialogPrompt/DialogPromptEnterNameComponent';
import DialogPromptImageComponent from './dialogPrompt/DialogPromptImageComponent';
import DialogPromptIntroduceWordComponent from './dialogPrompt/DialogPromptIntroduceWordComponent';
import DialogPromptLearnWithCharacterComponent from './dialogPrompt/DialogPromptLearnWithCharacterComponent';
import DialogPromptListenSpeakComponent from './dialogPrompt/DialogPromptListenSpeakComponent';
import DialogPromptListenSpeakNoGradeComponent from './dialogPrompt/DialogPromptListenSpeakNoGradeComponent';
import DialogPromptMatchWordPictureComponent from './dialogPrompt/DialogPromptMatchWordPictureComponent';
import DialogPromptQuizQuestionAudioComponent from './dialogPrompt/DialogPromptQuizQuestionAudioComponent';
import DialogPromptQuizQuestionComponent from './dialogPrompt/DialogPromptQuizQuestionComponent';
import DialogPromptQuizWordComponent from './dialogPrompt/DialogPromptQuizWordComponent';
import DialogPromptSelectAvatarComponent from './dialogPrompt/DialogPromptSelectAvatarComponent';
import DialogPromptSelectLengthTimeComponent from './dialogPrompt/DialogPromptSelectLengthTimeComponent';
import DialogPromptSelectNextDialogComponent from './dialogPrompt/DialogPromptSelectNextDialogComponent';
import DialogPromptSelectTeacherComponent from './dialogPrompt/DialogPromptSelectTeacherComponent';
import DialogPromptSpeakNoGradeComponent from './dialogPrompt/DialogPromptSpeakNoGradeComponent';
import DialogPromptSpeakWithGradeComponent from './dialogPrompt/DialogPromptSpeakWithGradeComponent';
import DialogPromptStartClassComponent from './dialogPrompt/DialogPromptStartClassComponent';
import DialogPromptStartNextLessonComponent from './dialogPrompt/DialogPromptStartNextLessonComponent';
import DialogPromptTypeSpeakComponent from './dialogPrompt/DialogPromptTypeSpeakComponent';

interface DialogPageProps {
    dialog: IDialog;
}

const DialogPage: React.FC<DialogPageProps> = ({ dialog }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)

    const storedLearnWithCharacter = localStorage.getItem('learnWithCharacter');
    const learnWithCharacter = storedLearnWithCharacter ? JSON.parse(storedLearnWithCharacter) : false;

    const [courseName, setCourseName] = React.useState("");
    const [lessonName, setLessonName] = React.useState("");
    const [dialogTitle, setDialogTitle] = React.useState(dialog.title);

    const [dialogPromptList, setDialogPromptList] = React.useState<DialogPrompt[]>([]);
    const [currentDialogPromptIndex, setCurrentDialogPromptIndex] = React.useState(1);

    const [originalDialogPromptIdList, setOriginalDialogPromptIdList] = React.useState<number[]>([]);
    const [currentOriginalDialogPromptIdIndex, setCurrentOriginalDialogPromptIdIndex] = React.useState(1);

    const [promptInputComponent, setPromptInputComponent] = React.useState<React.ReactNode>(null);

    const chatBubbleContainerRef = React.useRef<HTMLDivElement>(null);

    const [isModalOpen, setModalOpen] = React.useState(false);

    const [noteToStudent, setNoteToStudent] = React.useState<(string | null)[]>([]);

    const history = useHistory();

    React.useEffect(() => {

        setCurrentDialogPromptIndex(1)

        setDialogTitle(dialog.title);

        LessonApi.getById(dialog.lesson_id)
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error("Unable to LessonApi.getById() " + response.status)
            })
            .then(lesson => {
                setLessonName(lesson.name)
                CourseApi.getById(lesson.course_id)
                    .then(response => {
                        if (response.status < 300) {
                            return response.data;
                        }
                        throw new Error("Unable to CourseApi.getById() " + response.status)
                    })
                    .then(course => setCourseName(course.name))
                    .catch(err => {
                        handleErrorMessage(err, setErrorMessage, history);
                    })
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history);
            })

        DialogPromptApi.getAll(dialog.id)
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error("Unable to DialogPromptApi.getAll() " + response.status)

            })
            .then(list => {
                addDialogPromptList(list, false)
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history);
            })

    }, [dialog]);

    React.useEffect(() => {

        const displayedList = dialogPromptList.slice(0, currentDialogPromptIndex);

        // Loop through the filtered list and add non-empty note_to_student values
        const nonEmptyNotes = displayedList
            .filter(prompt => prompt.is_note_to_student)
            .map(prompt => prompt.prompt);
        setNoteToStudent(nonEmptyNotes)

    }, [dialogPromptList, currentDialogPromptIndex])

    React.useEffect(() => {
        if (chatBubbleContainerRef.current) {
            chatBubbleContainerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [currentDialogPromptIndex]);

    const setDialogPromptComplete = (dialogPromptId: number) => {

        // check the dialogPromptId, old dialogs can be clicked on.
        const currentDialogPrompt = dialogPromptList[currentDialogPromptIndex - 1];
        if (currentDialogPrompt.id == dialogPromptId) {
            // currentDialogPromptIndex should always be right ... but search by ID anyway
            const slicedDialogPromptList = dialogPromptList.slice(currentDialogPromptIndex - 1);

            // Use findIndex() on the sliced array to find the next index of the dialogPromptId
            const indexOfTargetId = slicedDialogPromptList.findIndex((dialogPrompt) => dialogPrompt.id === dialogPromptId);

            // If the indexOfTargetId is not -1 (i.e., the targetId is found), adjust the index based on the currentDialogPromptIndex
            const finalIndex = indexOfTargetId !== -1 ? indexOfTargetId + currentDialogPromptIndex - 1 : -1;
            if (finalIndex >= 0) {
                // +1 because 1 based, +1 to advance to next dialog prompt
                setCurrentDialogPromptIndex(finalIndex + 2);
            } else {
                // if not found, just goto next index
                setCurrentDialogPromptIndex((prevIndex) => (prevIndex + 1));
            }

            // also update index of original dialog prompt
            const indexOfId = originalDialogPromptIdList.indexOf(dialogPromptId);
            // +1 because 1 based, +1 to advance to next dialog prompt
            setCurrentOriginalDialogPromptIdIndex(indexOfId + 2);
        }

    }

    const addDialog = (dialogId: number) => {
        DialogPromptApi.getAll(dialogId)
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error("Unable to DialogPromptApi.getAll() " + response.status)

            })
            .then(newList => {
                addDialogPromptList(newList, true)
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history);
            })
    }


    const addDialogPromptList = (list: DialogPrompt[], append = false) => {
        if (learnWithCharacter == false) {
            // if we're not learning characters, then filter out dialog prompts of subclass DialogPromptQuizWord
            list = list.filter(prompt => prompt.class_name != "DialogPromptQuizWordModel");
        }
        const finalList = list.map(async (prompt) => {
            if (prompt.class_name == "DialogPromptMatchWordPictureModel" ||
                prompt.class_name == "DialogPromptTypeSpeakModel" ||
                prompt.class_name == "DialogPromptQuizQuestionModel" ||
                prompt.class_name == "DialogPromptQuizQuestionAudioModel" ||
                prompt.class_name == "DialogPromptConversationListenModel" ||
                prompt.class_name == "DialogPromptConversationListenSpeakModel" ||
                prompt.class_name == "DialogPromptConversationListenWithoutCharacterModel" ||
                prompt.class_name == "DialogPromptConversationReadSpeakModel" ||
                prompt.class_name == "DialogPromptConversationReadWithBlankSpeakModel" ||
                prompt.class_name == "DialogPromptConversationSpeakOnlyModel" ||
                prompt.class_name == "DialogPromptSpeakNoGradeModel" ||
                prompt.class_name == "DialogPromptSpeakWithGradeModel") {
                try {
                    const response = await DialogPromptApi.getById(prompt.id) as AxiosResponse<DialogPrompt>;
                    // console.log('Type of addDialogPromptList.getById():', response.data.constructor.name);
                    // const dialogPrompt: DialogPrompt = response.data as DialogPrompt;
                    // console.log('Type of addDialogPromptList.dialogPrompt:', dialogPrompt.constructor.name);
                    return response.data; // Replace the prompt with the data obtained from the API
                } catch (err: any) {
                    if (err.response && err.response.status === 401) {
                                        handleErrorMessage(err, setErrorMessage, history);

                    } else {
                        console.error(err)
                        handleErrorMessage(err, setErrorMessage, history);
                    }
                    return prompt;
                }
            }
            return prompt; // Keep other prompts as they are
        });

        // Wait for all the API calls to resolve
        Promise.all(finalList)
            .then((updatedList) => {
                const idList = updatedList.map((prompt) => prompt.id);
                if (append) {
                    setDialogPromptList((prevList) => [...prevList, ...updatedList]);
                    setOriginalDialogPromptIdList((prevList) => [...prevList, ...idList]);
                } else {
                    setDialogPromptList(updatedList);
                    setOriginalDialogPromptIdList(idList);
                }
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history);
            });
    }

    const [openGoBackDialog, setOpenGoBackDialog] = React.useState(false);

    const handleGoBackClick = () => {
        setOpenGoBackDialog(true);
    };

    const handleGoBackDialogConfirm = () => {
        setOpenGoBackDialog(false);

        let lastIndexWithUserInput = -1; // Initialize with -1 to indicate not found

        // Use a for loop to find the index of the last occurrence where has_user_input is true before the nth element
        // additional -1 because 1 based
        for (let i = currentOriginalDialogPromptIdIndex - 2; i >= 0; i--) {
            const targetId = originalDialogPromptIdList[i];
            // Use the find() method to search for the first element with the target ID
            const dialogPromptMap = dialogPromptList.find((dialogPrompt) => dialogPrompt.id === targetId);
            if (dialogPromptMap) {
                const dialgoPromptObject = DialogPromptFactory(
                    dialogPromptMap.class_name,
                    dialogPromptMap.id,
                    dialogPromptMap.dialog_id,
                    dialogPromptMap.order_index,
                    dialogPromptMap.is_teacher,
                    dialogPromptMap.prompt,
                    dialogPromptMap.is_note_to_student);

                if (dialgoPromptObject && dialgoPromptObject.has_user_input) {
                    lastIndexWithUserInput = i;
                    break; // Found the last occurrence, exit the loop
                }
            }
        }
        if (lastIndexWithUserInput < 0) lastIndexWithUserInput = 0;

        const repeatIdSlice = originalDialogPromptIdList.slice(lastIndexWithUserInput, currentOriginalDialogPromptIdIndex - 1);
        // Use the spread operator (...) to shallow copy the objects in the repeatSlice
        const copiedRepeatSlice = repeatIdSlice.map((dialogPromptId) => {
            // Use the find() method to search for the first element with the dialogPromptId
            const dialogPrompt = dialogPromptList.find((dialogPrompt) => dialogPrompt.id === dialogPromptId);
            // return copy of prompt
            return { ...dialogPrompt } as DialogPrompt
        });
        // insert right at current dialog prompt
        const i = currentDialogPromptIndex - 1;
        const updatedDialogPromptList = [...dialogPromptList.slice(0, i), ...copiedRepeatSlice, ...dialogPromptList.slice(i)];
        setDialogPromptList(updatedDialogPromptList);

        // don't forget to update original dialog prompt variables
        // +1 because 1-based
        setCurrentOriginalDialogPromptIdIndex(lastIndexWithUserInput + 1)

    };

    const handleGoBackDialogCancel = () => {
        setOpenGoBackDialog(false);
    };

    const handleNoteClick = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    return (
        <Stack sx={{ width: '100%', margin: '0 auto' }}>
            <div style={{ height: 'calc(100vh - 255px)', overflowY: 'auto' }}>
                {dialogPromptList.slice(0, currentDialogPromptIndex).map((dialogPrompt, index) => (
                    DialogPromptComponentFactory(dialogPrompt, setDialogPromptComplete, setPromptInputComponent, addDialog, chatBubbleContainerRef)
                ))}
                <div ref={chatBubbleContainerRef} />
            </div>
            <Divider sx={{ width: '100%' }} />
            <Stack direction="row" alignItems="center" justifyContent="center" sx={{ my: 1, minHeight: '32px' }}>
                {currentOriginalDialogPromptIdIndex == 1 ? (
                    <IconButton>
                        <Icon fontSize="large" />
                    </IconButton>
                ) : (
                    <IconButton onClick={handleGoBackClick}>
                        <img src={LeftArrowBlueIcon} style={{ width: '36px', height: '36px', marginLeft: '8px' }} />
                        {/* <ArrowBackRoundedIcon fontSize="large" /> */}
                    </IconButton>
                )}

                <Stack sx={{ flexGrow: 1 }}>
                    {promptInputComponent}
                </Stack>
                <IconButton onClick={handleNoteClick} sx={{ mr: 2 }}>
                    <img src={NoteBlueIcon} style={{ width: '36px', height: '36px' }} />
                </IconButton>
                <Modal
                    open={isModalOpen}
                    onClose={handleModalClose}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            backgroundColor: '#fff',
                            padding: '2rem',
                            outline: 'none',
                        }}
                    >
                        <Stack spacing={2} alignItems="center">
                            <Typography variant="h5" id="modal-title" style={{ paddingBottom: '1em' }}>
                                Notes
                            </Typography>
                            {noteToStudent.map((note, index) => (
                                <Typography variant="body1" id="modal-description" key={index}>
                                    {note}
                                </Typography>
                            ))}
                        </Stack>
                    </div>
                </Modal>
                {/* Go Back Confirmation Dialog */}
                <Dialog open={openGoBackDialog} onClose={handleGoBackDialogCancel}>
                    <DialogTitle><Typography variant="h4" sx={{ color: '#383838' }}>Confirm Repeat</Typography></DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ color: '#8D8D8D' }}>
                            Are you sure you want to repeat the last exercise?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'space-between', '& .MuiButton-root': { flex: 1 } }}>
                        <Button onClick={handleGoBackDialogCancel} color="primary" sx={{ color: '#787878' }}>
                            Not Repeat
                        </Button>
                        <Button onClick={handleGoBackDialogConfirm} color="primary" sx={{ color: '#787878' }}>
                            Repeat
                        </Button>
                    </DialogActions>
                </Dialog>
            </Stack>
        </Stack >
    );
};

export default DialogPage;

export function DialogPromptComponentFactory(dialogPrompt: DialogPrompt,
    setDialogPromptComplete: (dialogPromptId: number) => void,
    setPromptInputComponent: (component: React.ReactNode | null) => void,
    addDialog: (dialogId: number) => void,
    chatBubbleContainerRef: React.RefObject<HTMLDivElement>,
    showAvatar: boolean = true,
    isSettings: boolean = false) {
    if (dialogPrompt.class_name == "DialogPromptModel") {
        return (<DialogPromptComponent dialogPrompt={dialogPrompt} setDialogPromptComplete={setDialogPromptComplete} setPromptInputComponent={setPromptInputComponent} key={dialogPrompt.id} showAvatar={showAvatar} chatBubbleContainerRef={chatBubbleContainerRef} isSettings={isSettings} />)
    } else if (dialogPrompt.class_name == "DialogPromptEnterNameModel") {
        return (<DialogPromptEnterNameComponent dialogPrompt={dialogPrompt} setDialogPromptComplete={setDialogPromptComplete} setPromptInputComponent={setPromptInputComponent} key={dialogPrompt.id} showAvatar={showAvatar} chatBubbleContainerRef={chatBubbleContainerRef} isSettings={isSettings}/>)
    } else if (dialogPrompt.class_name == "DialogPromptImageModel") {
        return (<DialogPromptImageComponent dialogPrompt={dialogPrompt as DialogPromptImage} setDialogPromptComplete={setDialogPromptComplete} setPromptInputComponent={setPromptInputComponent} key={dialogPrompt.id} showAvatar={showAvatar} chatBubbleContainerRef={chatBubbleContainerRef} isSettings={isSettings} />)
    } else if (dialogPrompt.class_name == "DialogPromptIntroduceWordModel") {
        return (<DialogPromptIntroduceWordComponent dialogPrompt={dialogPrompt as DialogPromptIntroduceWord} setDialogPromptComplete={setDialogPromptComplete} setPromptInputComponent={setPromptInputComponent} key={dialogPrompt.id} showAvatar={showAvatar} chatBubbleContainerRef={chatBubbleContainerRef} isSettings={isSettings} />)
    } else if (dialogPrompt.class_name == "DialogPromptLearnWithCharacterModel") {
        return (<DialogPromptLearnWithCharacterComponent dialogPrompt={dialogPrompt as DialogPromptLearnWithCharacter} setDialogPromptComplete={setDialogPromptComplete} setPromptInputComponent={setPromptInputComponent} key={dialogPrompt.id} showAvatar={showAvatar} chatBubbleContainerRef={chatBubbleContainerRef} isSettings={isSettings} />)
    } else if (dialogPrompt.class_name == "DialogPromptListenSpeakModel") {
        return (<DialogPromptListenSpeakComponent dialogPrompt={dialogPrompt as DialogPromptListenSpeak} setDialogPromptComplete={setDialogPromptComplete} setPromptInputComponent={setPromptInputComponent} key={dialogPrompt.id} showAvatar={showAvatar} chatBubbleContainerRef={chatBubbleContainerRef} isSettings={isSettings} />)
    } else if (dialogPrompt.class_name == "DialogPromptListenSpeakNoGradeModel") {
        return (<DialogPromptListenSpeakNoGradeComponent dialogPrompt={dialogPrompt as DialogPromptListenSpeakNoGrade} setDialogPromptComplete={setDialogPromptComplete} setPromptInputComponent={setPromptInputComponent} key={dialogPrompt.id} showAvatar={showAvatar} chatBubbleContainerRef={chatBubbleContainerRef} isSettings={isSettings} />)
    } else if (dialogPrompt.class_name == "DialogPromptQuizWordModel") {
        return (<DialogPromptQuizWordComponent dialogPrompt={dialogPrompt as DialogPromptQuizWord} setDialogPromptComplete={setDialogPromptComplete} setPromptInputComponent={setPromptInputComponent} key={dialogPrompt.id} showAvatar={showAvatar} chatBubbleContainerRef={chatBubbleContainerRef} isSettings={isSettings} />)
    } else if (dialogPrompt.class_name == "DialogPromptSelectAvatarModel") {
        return (<DialogPromptSelectAvatarComponent dialogPrompt={dialogPrompt} setDialogPromptComplete={setDialogPromptComplete} setPromptInputComponent={setPromptInputComponent} key={dialogPrompt.id} showAvatar={showAvatar} chatBubbleContainerRef={chatBubbleContainerRef} isSettings={isSettings} />)
    } else if (dialogPrompt.class_name == "DialogPromptSelectTeacherModel") {
        return (<DialogPromptSelectTeacherComponent dialogPrompt={dialogPrompt as DialogPromptSelectTeacher} setDialogPromptComplete={setDialogPromptComplete} setPromptInputComponent={setPromptInputComponent} key={dialogPrompt.id} showAvatar={showAvatar} chatBubbleContainerRef={chatBubbleContainerRef} isSettings={isSettings} />)
    } else if (dialogPrompt.class_name == "DialogPromptStartClassModel") {
        return (<DialogPromptStartClassComponent dialogPrompt={dialogPrompt as DialogPromptStartClass} setDialogPromptComplete={setDialogPromptComplete} setPromptInputComponent={setPromptInputComponent} addDialog={addDialog} key={dialogPrompt.id} showAvatar={showAvatar} chatBubbleContainerRef={chatBubbleContainerRef} isSettings={isSettings} />)
    } else if (dialogPrompt.class_name == "DialogPromptStartNextLessonModel") {
        return (<DialogPromptStartNextLessonComponent dialogPrompt={dialogPrompt as DialogPromptStartNextLesson} setDialogPromptComplete={setDialogPromptComplete} setPromptInputComponent={setPromptInputComponent} addDialog={addDialog} key={dialogPrompt.id} showAvatar={showAvatar} chatBubbleContainerRef={chatBubbleContainerRef} isSettings={isSettings} />)
    } else if (dialogPrompt.class_name == "DialogPromptTypeSpeakModel") {
        return (<DialogPromptTypeSpeakComponent dialogPrompt={dialogPrompt as DialogPromptTypeSpeak} setDialogPromptComplete={setDialogPromptComplete} setPromptInputComponent={setPromptInputComponent} key={dialogPrompt.id} showAvatar={showAvatar} chatBubbleContainerRef={chatBubbleContainerRef} isSettings={isSettings} />)
    } else if (dialogPrompt.class_name == "DialogPromptMatchWordPictureModel") {
        return (<DialogPromptMatchWordPictureComponent dialogPrompt={dialogPrompt as DialogPromptMatchWordPicture} setDialogPromptComplete={setDialogPromptComplete} setPromptInputComponent={setPromptInputComponent} key={dialogPrompt.id} showAvatar={showAvatar} chatBubbleContainerRef={chatBubbleContainerRef} isSettings={isSettings} />)
    } else if (dialogPrompt.class_name == "DialogPromptQuizQuestionModel") {
        return (<DialogPromptQuizQuestionComponent dialogPrompt={dialogPrompt as DialogPromptQuizQuestion} setDialogPromptComplete={setDialogPromptComplete} setPromptInputComponent={setPromptInputComponent} key={dialogPrompt.id} showAvatar={showAvatar} chatBubbleContainerRef={chatBubbleContainerRef} isSettings={isSettings} />)
    } else if (dialogPrompt.class_name == "DialogPromptQuizQuestionAudioModel") {
        return (<DialogPromptQuizQuestionAudioComponent dialogPrompt={dialogPrompt as DialogPromptQuizQuestionAudio} setDialogPromptComplete={setDialogPromptComplete} setPromptInputComponent={setPromptInputComponent} key={dialogPrompt.id} showAvatar={showAvatar} chatBubbleContainerRef={chatBubbleContainerRef} isSettings={isSettings} />)
    } else if (dialogPrompt.class_name == "DialogPromptConversationListenModel") {
        return (<DialogPromptConversationListenComponent dialogPrompt={dialogPrompt as DialogPromptConversationListen} setDialogPromptComplete={setDialogPromptComplete} setPromptInputComponent={setPromptInputComponent} key={dialogPrompt.id} showAvatar={showAvatar} chatBubbleContainerRef={chatBubbleContainerRef} isSettings={isSettings} />)
    } else if (dialogPrompt.class_name == "DialogPromptConversationListenSpeakModel") {
        return (<DialogPromptConversationRelatedComponent dialogPrompt={dialogPrompt as DialogPromptConversationListenSpeak} setDialogPromptComplete={setDialogPromptComplete} setPromptInputComponent={setPromptInputComponent} key={dialogPrompt.id} showAvatar={showAvatar} chatBubbleContainerRef={chatBubbleContainerRef} isSettings={isSettings} />)
    } else if (dialogPrompt.class_name == "DialogPromptConversationListenWithoutCharacterModel") {
        return (<DialogPromptConversationListenComponent dialogPrompt={dialogPrompt as DialogPromptConversationListenWithoutCharacter} setDialogPromptComplete={setDialogPromptComplete} setPromptInputComponent={setPromptInputComponent} key={dialogPrompt.id} showAvatar={showAvatar} chatBubbleContainerRef={chatBubbleContainerRef} isSettings={isSettings} />)
    } else if (dialogPrompt.class_name == "DialogPromptConversationReadSpeakModel") {
        return (<DialogPromptConversationRelatedComponent dialogPrompt={dialogPrompt as DialogPromptConversationReadSpeak} setDialogPromptComplete={setDialogPromptComplete} setPromptInputComponent={setPromptInputComponent} key={dialogPrompt.id} showAvatar={showAvatar} chatBubbleContainerRef={chatBubbleContainerRef} isSettings={isSettings} />)
    } else if (dialogPrompt.class_name == "DialogPromptConversationReadWithBlankSpeakModel") {
        return (<DialogPromptConversationRelatedComponent dialogPrompt={dialogPrompt as DialogPromptConversationReadWithBlankSpeak} setDialogPromptComplete={setDialogPromptComplete} setPromptInputComponent={setPromptInputComponent} key={dialogPrompt.id} showAvatar={showAvatar} chatBubbleContainerRef={chatBubbleContainerRef} isSettings={isSettings} />)
    } else if (dialogPrompt.class_name == "DialogPromptConversationSpeakOnlyModel") {
        return (<DialogPromptConversationRelatedComponent dialogPrompt={dialogPrompt as DialogPromptConversationSpeakOnly} setDialogPromptComplete={setDialogPromptComplete} setPromptInputComponent={setPromptInputComponent} key={dialogPrompt.id} showAvatar={showAvatar} chatBubbleContainerRef={chatBubbleContainerRef} isSettings={isSettings} />)
    } else if (dialogPrompt.class_name == "DialogPromptSelectLengthTimeModel") {
        return (<DialogPromptSelectLengthTimeComponent dialogPrompt={dialogPrompt as DialogPromptConversationSpeakOnly} setDialogPromptComplete={setDialogPromptComplete} setPromptInputComponent={setPromptInputComponent} key={dialogPrompt.id} showAvatar={showAvatar} chatBubbleContainerRef={chatBubbleContainerRef} isSettings={isSettings} />)
    } else if (dialogPrompt.class_name == "DialogPromptSelectNextDialogModel") {
        return (<DialogPromptSelectNextDialogComponent dialogPrompt={dialogPrompt as DialogPromptSelectNextDialog} setDialogPromptComplete={setDialogPromptComplete} setPromptInputComponent={setPromptInputComponent} key={dialogPrompt.id} showAvatar={showAvatar} chatBubbleContainerRef={chatBubbleContainerRef} isSettings={isSettings} addDialog={addDialog} />)
    } else if (dialogPrompt.class_name == "DialogPromptSpeakNoGradeModel") {
        return (<DialogPromptSpeakNoGradeComponent dialogPrompt={dialogPrompt as DialogPromptSpeakNoGrade} setDialogPromptComplete={setDialogPromptComplete} setPromptInputComponent={setPromptInputComponent} key={dialogPrompt.id} showAvatar={showAvatar} chatBubbleContainerRef={chatBubbleContainerRef} isSettings={isSettings} />)
    } else if (dialogPrompt.class_name == "DialogPromptSpeakWithGradeModel") {
        return (<DialogPromptSpeakWithGradeComponent dialogPrompt={dialogPrompt as DialogPromptSpeakWithGrade} setDialogPromptComplete={setDialogPromptComplete} setPromptInputComponent={setPromptInputComponent} key={dialogPrompt.id} showAvatar={showAvatar} chatBubbleContainerRef={chatBubbleContainerRef} isSettings={isSettings} />)
    } else {
        throw new Error("unknown class name " + dialogPrompt.class_name)
    }
}
