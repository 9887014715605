import '../style/global.css';

import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import { useHistory } from 'react-router-dom';
import { CommentApi, IComment } from '../api/CommentApi';
import background from '../asset/background-signin.png';
import ErrorMessageContext, { handleErrorMessage } from "../context/ErrorMessageContext";

export default function BugReport() {
    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();

    const [ submitMessage, setSubmitMessage ] = React.useState("")

    const [comment, setComment] = React.useState<IComment>({
        id: 0, // Assuming this is set automatically or elsewhere since it's an ID
        hsk_level: null,
        good_comment: null,
        bad_comment: null,
        bug_report: null,
    });

    const handleSelectChange = (event: SelectChangeEvent) => {
        setSubmitMessage("")
        const name = event.target.name as keyof typeof comment;
        const value = event.target.value;

        setComment(prev => ({
            ...prev,
            [name]: name === 'hsk_level' ? parseInt(value as string, 10) : value,
        }));
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSubmitMessage("")
        const { name, value } = event.target;
        setComment(prev => ({
            ...prev,
            [name]: name === 'hsk_level' ? parseInt(value, 10) : value,
        }));
    };
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        // console.log(comment);
        setSubmitMessage("")
        CommentApi.create(comment)
            .then(response => {
                if (response.status < 300) {
                    return response.data
                }
                console.error(response)
                handleErrorMessage(response, setErrorMessage, history);
                return []
            })
            .then(list => {
                // do nothing right?
                setSubmitMessage("Thank you for your feedback!")
            })
            .catch(err => {
                handleErrorMessage(err, setErrorMessage, history);
            })
    };

    return (
        <div style={{
            display: 'flex', // Use flex to allow easy centering
            flexDirection: 'column', // Changed to stack children vertically
            justifyContent: 'center', // Center horizontally
            alignItems: 'center', // Center vertically
            minHeight: 'calc(100vh - 85px)', // Ensure the div takes at least the full viewport height
            width: '100vw', // Ensure the div takes the full viewport width
            padding: 0, // Reset any padding
            backgroundImage: `url(${background})`, // Your background image
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover', // Cover the entire size of the div
            backgroundPosition: 'center', // Center the background image
        }}>
            <Typography variant="h4">What do you think?</Typography>
            <Box
                sx={{
                    mt: 2,
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: 'calc(100vh - 150px)', // Viewport height minus 100px
                    width: '50%', // Set the width of the Box
                    backgroundColor: '#FFFFFF',
                    borderRadius: '16px',
                    overflowY: 'auto', // Allows content to scroll if it overflows the Y axis
                    border: '1.5px solid rgba(82, 99, 125, 0.2)', // Added border with color and transparency
                }}
            >
                <Box
                    component="form"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        '& .MuiTextField-root': { m: 1, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit}
                >
                    <TextField
                        label="Keep doing this, I like it"
                        multiline
                        minRows={3}
                        name="good_comment"
                        value={comment.good_comment}
                        onChange={handleInputChange}
                        fullWidth
                    // variant="outlined"
                    //sx={{ width: '1000px' }}
                    />
                    <TextField
                        label="Please start doing this"
                        multiline
                        minRows={3}
                        name="bad_comment"
                        value={comment.bad_comment}
                        onChange={handleInputChange}
                        fullWidth
                    //variant="outlined"
                    //sx={{ width: '500px' }}
                    />
                    <TextField
                        label="This looks broken to me"
                        multiline
                        minRows={3}
                        name="bug_report"
                        value={comment.bug_report}
                        onChange={handleInputChange}
                        fullWidth
                    //variant="outlined"
                    //sx={{ width: '500px' }}
                    />
                    <div style={{ display: 'flex', gap: '1em', alignItems: 'flex-start' }}>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="hsk-level-select-label">Your HSK Level</InputLabel>
                            <Select
                                labelId="hsk-level-select-label"
                                id="hsk-level-select"
                                value={comment.hsk_level ? comment.hsk_level.toString() : "1"}
                                label="Your HSK Level"
                                onChange={handleSelectChange}
                                name="hsk_level"
                            >
                                {Array.from({ length: 9 }, (_, i) => i + 1).map(level => (
                                    <MenuItem key={level} value={level}>
                                        HSK {level}
                                        {level <= 3 ? " (beginner)" : (level <= 6 ? " (intermediate)" : " (advanced)")}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button type="submit" variant="contained" color="primary" sx={{ alignSelf: 'center' }}>
                            Submit
                        </Button>
                    </div>
                </Box>
                <Typography variant="h5">{submitMessage}</Typography>
            </Box>
        </div>
    );
}