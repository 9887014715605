import axios from 'axios';
import { REST_HOSTNAME } from '../Config';
import { IDialogPromptSentence } from './DialogPromptApi';

const axiosInstance = axios.create({
    baseURL: REST_HOSTNAME
});

// Add request interceptor
axiosInstance.interceptors.request.use(
    function (config) {
        const jwt = localStorage.getItem('jwt');
        if (jwt) {
            config.headers['x-access-token'] = jwt; // Add JWT to the headers
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export interface IDialogPromptConversation {
    id: number;
    name: string | null;
    dialog_prompt_sentence_list: IDialogPromptSentence[];
}

export const DialogPromptConversationApi = {
    getAll: function () {
        return axiosInstance.request<IDialogPromptConversation[]>({
            method: "GET",
            url: `/dialog_prompt_conversation`
        });
    },
    getById: function (dialogPromptConversationId: number) {
        return axiosInstance.request<IDialogPromptConversation>({
            method: "GET",
            url: `/dialog_prompt_conversation/${dialogPromptConversationId}`
        });
    },
    create: function (dialogpromptconversation: IDialogPromptConversation) {
        return axiosInstance.request<IDialogPromptConversation>({
            method: "POST",
            url: `/dialog_prompt_conversation`,
            data: dialogpromptconversation
        });
    },
    update: function (dialogpromptconversation: IDialogPromptConversation) {
        return axiosInstance.request<string>({
            method: "PUT",
            url: `/dialog_prompt_conversation`,
            data: dialogpromptconversation
        });
    },
    delete: function (dialogpromptconversationId: number) {
        return axiosInstance.request<string>({
            method: "DELETE",
            url: `/dialog_prompt_conversation/${dialogpromptconversationId}`,
        });
    },
}
